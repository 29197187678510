import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "services/auth";
import Spinner from "elements/Spinner";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { __ } from "services/Translator";
// import passwordValidate from "services/passwordValidate";

// import SocialLogin from "elementsSocialLogin";
import { Modal, Button, Alert } from "react-bootstrap";

const Login = (props) => {
  const history = useHistory();
  const setSpinner = props.setSpinner;
  // loginModal
  const showLoginModal = props.displayedModal === "login";
  const showRecoverPassModal = props.displayedModal === "forgotpass";
  const openLoginModal = () => {
    props.setDisplayedModal("login");
  };
  const openRecoverPassModal = () => {
    props.setDisplayedModal("forgotpass");
  };
  const openSignupModal = () => {
    props.setDisplayedModal("signup");
  };
  const closeAllModals = () => {
    props.setDisplayedModal(null);
  };

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    setForm({
      email: "",
      password: "",
    });
  }, []);

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(value, field);
  };

  const doLogin = async (ev) => {
    ev.preventDefault();
    setSpinner(true);
    var response = await api.post("/login", form);
    setSpinner(false);
    if (response.status === 200) {
      closeAllModals();
      props.showAlert(
        "Success!!",
        "Logged in successfully",
        false,
        async () => {
          await auth.login(response.data.user);
          updateUserObj();
        },
        1000
      );
    } else if (response.status === 423) {
      //Activation pending
      props.showAlert(
        __("Acount not activated") + "!!",
        response.data.message +
          "\n\n" +
          __(
            "If you did not receive the activation email, please close this message to go to the activation page."
          ),
        true,
        () => {
          closeAllModals();
          history.push("/activate-account/" + form.email);
        }
      );
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  //forgot Password functionality START'S here
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  useEffect(() => {
    setEmail("");
    setSentForgotPassMail(false);
  }, []);
  const [sentForgotPassMail, setSentForgotPassMail] = useState(false);

  const resetPassword = async (ev) => {
    ev.preventDefault();
    setSpinner(true);
    setEmailError(null);
    var response = await api.post("/request-reset-password", { email: email });
    setSpinner(false);
    if (response.status === 200) {
      setSentForgotPassMail(true);
    } else {
      setEmailError(response.data.message);
    }
  };
  //forgot password functionality END'S here

  return (
    <>
      <Modal show={showLoginModal} onHide={closeAllModals} id="loginModal">
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Log In")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login_area">
            <h5 className="mobile_title">{__("Log In")}</h5>

            <form id="loginModalForm" method="post" onSubmit={doLogin}>
              <div className="login_input">
                <input
                  type="email"
                  required
                  placeholder={__("E-mail")}
                  className="form-control emailInput"
                  name="email"
                  value={form.email}
                  onChange={updateInput}
                />
              </div>
              <br />
              <div className="login_input">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  placeholder={__("Password")}
                  className="form-control passwordInput"
                  name="password"
                  value={form.password}
                  onChange={updateInput}
                />
                <a
                  className="show_hide_password"
                  onClick={togglePasswordVisible}
                  href="#"
                >
                  {isPasswordVisible ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </a>
              </div>
              <div className="modal_action_link">
                <a
                  className="forgot_password_modal_link"
                  onClick={openRecoverPassModal}
                  href="#"
                >
                  {__("Forgot Password")}?
                </a>
                <a
                  className="registration_modal_link"
                  onClick={openSignupModal}
                  href="#"
                >
                  {__("Don't have an acount? Sign Up!")}
                </a>
                <div className="clearfix"></div>
              </div>

              <input type="hidden" name="g-recaptcha-response" value="" />
              <input
                type="submit"
                className="form-control login_action"
                value={__("Log In")}
              />

              <div className="mobile_action_link">
                <a
                  className="forgot_password_modal_link"
                  href="#"
                  onClick={openRecoverPassModal}
                >
                  {__("Forgot Password")}?
                </a>
                <hr />
                <a
                  className="registration_modal_link"
                  onClick={openSignupModal}
                  href="#"
                >
                  {__("Don't have an acount? Sign Up!")}
                </a>
                <div className="clearfix"></div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRecoverPassModal}
        onHide={closeAllModals}
        id="forgotPasswordModal"
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Recover Your Password")}
          </h5>
          <button
            type="button"
            onClick={closeAllModals}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          {!sentForgotPassMail ? (
            <>
              <form method="post" onSubmit={resetPassword}>
                <h5 className="mobile_title">{__("Recover Your Password")}</h5>
                <div className="forgot_password_area" id="recover_form">
                  <input
                    type="text"
                    required
                    placeholder={__("E-mail")}
                    id="recover_email"
                    className="form-control"
                    name="email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    value={email}
                  />
                  <div className="form_error" id="pass_recover_error">
                    {emailError}
                  </div>
                  <br />

                  <input
                    type="submit"
                    title={__("Send password recovery link")}
                    className="form-control forgot_password_action"
                    value={__("Send password recovery link")}
                  />
                </div>
              </form>
            </>
          ) : (
            <p className="recovery_success">
              A password recovery email has been sent to your provided email
              address.
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
