import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import InputBox from "elements/InputBox";
import { Modal, Button, Alert } from "react-bootstrap";
import { __ } from "services/Translator";

export default function ResetPassword(props) {
  var params = useParams();

  const [changePasswordForm, setChangePasswordForm] = useState({
    user_id: params.userId,
    code: params.code,
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});

  const resetPasswordAction = async () => {
    var valid = validateForm();

    if (valid) {
      var response = await api.post(
        "/reset-password-action",
        changePasswordForm
      );

      if (response.status === 200) {
        props.showAlert("", response.data.message);
        setChangePasswordForm({
          user_id: params.userId,
          code: params.code,
          password: "",
          confirm_password: "",
        });
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  const validateForm = () => {
    var valid = true;

    var errorsTmp = {};

    if (
      changePasswordForm.password.length < 8 ||
      changePasswordForm.password.length > 30
    ) {
      errorsTmp = {
        ...errorsTmp,
        password: "Your password must contain between 8 and 30 characters",
      };
      valid = false;
    }

    if (changePasswordForm.password != changePasswordForm.confirm_password) {
      errorsTmp = { ...errorsTmp, confirm_password: "Password does not match" };
      valid = false;
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateForm = (field, value) => {
    setChangePasswordForm({ ...changePasswordForm, [field]: value });
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Reset Password") }]);
  }, []);

  return (
    <>
      <section className="content_section container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
              <div className="recovery_password_area">
                <h1 className="common_title">{__("Reset Password")}</h1>

                <div id="recoveryPasswordForm">
                  <div className="single_input">
                    <InputBox
                      type="password"
                      value={changePasswordForm.password}
                      onChange={(value) => updateForm("password", value)}
                      required
                      className="form-control password_input"
                      placeholder={__("Type a new password")}
                    />
                  </div>

                  <div className="form_error">{errors.password}</div>
                  <br />
                  <div className="single_input">
                    <InputBox
                      type="password"
                      required
                      className="form-control password_input"
                      value={changePasswordForm.confirm_password}
                      onChange={(value) =>
                        updateForm("confirm_password", value)
                      }
                      placeholder={__("Re-type new password")}
                    />
                  </div>
                  <div className="form_error">{errors.confirm_password}</div>
                  <br />
                  <input type="hidden" name="g-recaptcha-response" value="" />
                  <button
                    onClick={resetPasswordAction}
                    className="common_button recovery_password_action"
                  >
                    {__("Reset Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
