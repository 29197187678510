import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import { isMobile } from "services/isMobile";
import { __ } from "services/Translator";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";

import AccountPagesTemplate from "elements/AccountPagesTemplate";

export default function Cashier(props) {
  const depositAmounts = [10, 20, 50, 100, 200, 500, 1000, 5000];
  const [depositAmount, setDepositAmount] = useState("");
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositMethod, setDepositMethod] = useState(null);
  const [bonusCampaigns, setBonusCampaigns] = useState([]);
  const [bonusCampaign, setBonusCampaign] = useState("no_bonus");
  const [depositFinalData, setDepositFinalData] = useState(null);
  const [infoUrl, setInfoUrl] = useState("");

  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [depositForm, setDepositForm] = useState({});
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [deleteBonusModalOpen, setDeleteBonusModalOpen] = useState(false);
  const [customDepositAmountOpen, setCustomDepositAmountOpen] = useState(false);
  const [depositFinalModalOpen, setDepositFinalModalOpen] = useState(false);
  const [readCarefullyModalOpen, setReadCarefullyModalOpen] = useState(false);

  const toggleDepositModal = () => {
    if (!depositModalOpen) {
      getDepositMethods();
    }
    setDepositModalOpen(!depositModalOpen);
  };
  const toggleDeleteBonusModalOpen = () => {
    setDeleteBonusModalOpen(!deleteBonusModalOpen);
  };
  const toggleReadCarefullyModal = () => {
    setReadCarefullyModalOpen(!readCarefullyModalOpen);
  };

  useEffect(() => {
    getDepositMethods();
  }, [depositAmount]);

  const getDepositMethods = async () => {
    if (depositAmount) {
      props.setSpinner(true);
      var response = await api.post("/deposit/methods", {
        amount: depositAmount,
      });
      props.setSpinner(false);
      if (response.status === 200) {
        setDepositMethods(response.data.methods);
        if (
          !response.data.methods.filter(
            (method) => method.method_id === depositMethod
          ).length
        ) {
          setDepositMethod(null);
        }
        setBonusCampaigns(response.data.campaigns);
        if (
          !response.data.campaigns.filter(
            (campaign) => campaign.bonus_campaign.id === bonusCampaign
          ).length
        ) {
          setBonusCampaign("no_bonus");
        }
      }
    }
  };

  const submitDeposit = async (event) => {
    event.preventDefault();
    props.setSpinner(true);
    var response = await api.post("/deposit", {
      amount: depositAmount,
      method: depositMethod,
      campaign: bonusCampaign,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      if (
        response.data.status === "additional_fields" &&
        response.data.fields_for === "counos"
      ) {
        setDepositFinalData(response.data.data);
        setDepositFinalModalOpen(true);
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const doDeleteBonuses = async () => {
    props.setSpinner(true);
    var response = await api.post("/delete-bonus");
    if (response.status === 200) {
      props.setSpinner(false);
      toggleDeleteBonusModalOpen();
      props.getUserBalance();
      props.showAlert("Warning!!", response.data.message);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
    setDepositFinalData(null);
  }, []);

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Cashier") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Cashier")}</div>
        {!depositFinalData ? (
          <>
            <div className="chimp-accordions cashier_container">
              <div className="chimp-accordion">
                <div className="accordion_heading">
                  <i className="m-icon-grab"></i> {__("Balances")}
                </div>
                <div
                  className="accordion-body myaccount_input_area"
                  style={{ display: "block" }}
                >
                  <form
                    method="post"
                    className="details recommend_to_friend_form"
                  >
                    <div className="row">
                      <div className="col-sm-6">
                        <strong>{__("Total Balance")}</strong>
                        <br />
                        <input
                          type="text"
                          readOnly={true}
                          value={
                            "XBU " +
                            moneyFormatter.balanceFormat(
                              userBalance.total_balance
                            )
                          }
                          placeholder={__("Total Balance")}
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-6">
                        <strong>{__("Cash Balance")}</strong>
                        <br />
                        <input
                          type="text"
                          readOnly={true}
                          value={
                            "XBU " +
                            moneyFormatter.balanceFormat(
                              userBalance.cash_balance
                            )
                          }
                          placeholder={__("Cash Balance")}
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-6">
                        <strong>{__("Bonus Balance")}</strong>
                        <br />
                        <input
                          type="text"
                          readOnly={true}
                          value={
                            "XBB " +
                            moneyFormatter.balanceFormat(
                              userBalance.bonus_balance
                            )
                          }
                          className="form-control"
                          placeholder={__("Bonus Balance")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <strong>{__("Bonus Wallet Address")}</strong>
                        <br />
                        <input
                          type="text"
                          readOnly={true}
                          value={userDetails.wallet_address}
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-6">
                        <strong>&nbsp;</strong>
                        <br />
                        <a
                          href="#"
                          onClick={toggleDeleteBonusModalOpen}
                          className="delete_bonus common_brown_button"
                        >
                          {__("Delete Bonus")}
                        </a>
                      </div>
                      <div className="clearfix"></div>
                      <br />
                      <div className="col-md-12">
                        {!!parseFloat(
                          userBalance.wagering_requirements_total
                        ) && (
                          <div className="wagering_progress_container hidden">
                            <strong>{__("Bonus unlock progress")}</strong>
                            <div className="progress progress-striped active">
                              <div
                                className="progress-bar progress-bar-striped progress-bar-warning"
                                role="progressbar"
                                style={{
                                  width: userBalance.wagering_percentage + "%",
                                }}
                              ></div>
                            </div>
                            <div className="wagering_requirements_achieved_container">
                              (XBB {userBalance.wagering_requirements_achieved}
                              /XBB {userBalance.wagering_requirements_total})
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="balances-ctas">
              <a
                href="#"
                onClick={toggleDepositModal}
                className=" common_brown_button deposit_internal_btn"
              >
                {__("Deposit")}
              </a>{" "}
              <Link
                to="/cashier/withdraw"
                className=" common_brown_button withdraw_link"
              >
                {__("Withdraw")}
              </Link>
              <p className="cashier_info_text">
                {__(
                  "To charge your account with cryptocurrencies, you need to specify the amount and type of cryptocurrency in the system. Each time, given the live price of the chosen cryptocurrency, a new payment request is created."
                )}
                <br />
                {__(
                  "This payment request will include an address, a usage bar, and the calculated amount."
                )}
                <br />
                {__("You cannot use an address twice.")}
                <br />
                {__(
                  "Payments made outside the usual process of the system will be lost and not counted toward your balance."
                )}
                <br />
                {__(
                  "The payment needs to be made in the exact amount and to the exact same address."
                )}{" "}
                <br />
                {__(
                  "Sending a more or less amount and to a different address will result in your payment being lost and it will not be counted toward your balance."
                )}
                <br />
                {__(
                  "In order for your payment to be counted toward your balance, it needs to receive enough number of confirmations. "
                )}
                <br />
                {__(
                  "Depending on the speed of different networks, this can take up to 1 hour. So, please be patient. If after 2 hours, your payment is not added to your balance, please contact support."
                )}
                <br />
                {__(
                  "Full liability and responsibility for all the payments made outside of the process explained above is on the customer alone, and the website will bear no liability or responsibility for payments made outside of due process."
                )}
                <br />
              </p>
            </div>
            <Modal
              size="lg"
              show={depositModalOpen}
              onHide={toggleDepositModal}
            >
              <Modal.Header className="modal-header">
                <h5 className="modal-title">{__("Deposit")}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleDepositModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="deposit-methods">
                  <div className="deposit-method">
                    <div className="alert d-none alert-danger"></div>
                    <form
                      method="post"
                      id="internalDepositForm"
                      className="depositform"
                      action={""}
                      onSubmit={submitDeposit}
                    >
                      <strong>{__("Amount")}</strong>
                      <div className="chimp-radio-field">
                        {depositAmounts.map((val, index) => (
                          <label key={index} style={{ marginRight: 10 }}>
                            <input
                              type="radio"
                              className="deposit_campaign_check"
                              onChange={(ev) => {
                                setDepositAmount(val);
                                setCustomDepositAmountOpen(false);
                              }}
                              checked={depositAmount === val}
                              value={val}
                            />
                            {val}
                          </label>
                        ))}
                        <label style={{ marginRight: 10 }}>
                          <input
                            type="radio"
                            className="deposit_campaign_check"
                            onChange={(ev) => {
                              setDepositAmount(depositAmount.toString());
                              setCustomDepositAmountOpen(ev.target.checked);
                            }}
                            checked={customDepositAmountOpen}
                          />
                          {__("Custom Amount")}
                        </label>
                      </div>

                      {customDepositAmountOpen && (
                        <div className="reg-field">
                          <input
                            type="number"
                            className="custom_deposit_amount myaccount_input_value"
                            placeholder="Amount"
                            value={depositAmount.toString()}
                            onChange={(ev) =>
                              setDepositAmount(
                                parseInt(ev.target.value).toString()
                              )
                            }
                          />
                          <br />
                          &nbsp;
                          <br />
                        </div>
                      )}
                      <strong>{__("Payment Method")}</strong>
                      <div className="reg-field chimp-select-field payment_method_list">
                        <div className="banking_area">
                          <div className="banking_list_area">
                            <div className="small_info">
                              Click on the 'i' icon within a logo for more
                              information about a payment method.
                            </div>
                            {depositMethods.map((method, index) => (
                              <div className="single_banking e_wallet_method">
                                <input
                                  id={"all_" + method.id}
                                  onChange={() =>
                                    setDepositMethod(method.method_id)
                                  }
                                  type="radio"
                                  checked={method.method_id === depositMethod}
                                />
                                <label htmlFor={"all_" + method.id}>
                                  <ul>
                                    <li className="payment_logo_area">
                                      <a
                                        target="_blank"
                                        href="https://www.counos.io/CounosX"
                                        className="icon_popover"
                                      >
                                        i
                                      </a>
                                      <span className="helper"></span>
                                      <span className="payment_text_mobile">
                                        <span className="helper"></span>
                                        <span className="method_logo_text">
                                          {__("Method Logo")}
                                        </span>
                                      </span>
                                      <div
                                        className="method_logo"
                                        style={{
                                          backgroundImage:
                                            "url(" + method.logo + ")",
                                        }}
                                      ></div>
                                      <div className="clearfix"></div>
                                    </li>
                                    <li className="payment_method_name">
                                      <span className="helper"></span>
                                      <span className="payment_text_mobile">
                                        {__("Method Name")}
                                      </span>
                                      <span className="payment_content">
                                        {" "}
                                        {method.method}
                                      </span>
                                      <div className="clearfix"></div>
                                    </li>
                                    <li className="min_payment_amount">
                                      <span className="payment_text_mobile">
                                        {__("Limit Amount")}
                                      </span>
                                      <span className="payment_content">
                                        {__("MIN")} {method.min_withdrawal} XBU
                                        (1 XBU {__("Equivalent to 1 USD")})
                                        <br />
                                        <i>
                                          **{method.withdrawal_fee}%{" "}
                                          {__(
                                            "Transaction fee will be deducted from your withdrawal amount."
                                          )}
                                        </i>
                                      </span>
                                      <div className="clearfix"></div>
                                    </li>
                                  </ul>
                                </label>
                                <label
                                  htmlFor={"all_" + method.id}
                                  className="payment_check_mark"
                                >
                                  <i className="fas fa-check selected_method_icon"></i>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="campaigns-wrap">
                        <label>
                          <h3>{__("Bonus Campaigns")}</h3>
                        </label>
                        <div className="campaigns">
                          {bonusCampaigns.length ? (
                            <>
                              {bonusCampaigns.map((campaign, index) => (
                                <div className="campaign">
                                  <input
                                    type="radio"
                                    id={campaign.uniqid + "_id"}
                                    value={campaign.bonus_campaign.id}
                                    checked={
                                      bonusCampaign ===
                                      campaign.bonus_campaign.id
                                    }
                                    onChange={(ev) =>
                                      setBonusCampaign(
                                        campaign.bonus_campaign.id
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={campaign.uniqid + "_id"}
                                    className="check_box"
                                  >
                                    <i className="fas fa-check"></i>
                                  </label>{" "}
                                  <label
                                    htmlFor={campaign.uniqid + "_id"}
                                    className="campaign_label"
                                  >
                                    {campaign.bonus_campaign.title}
                                    {campaign.bonus_campaign
                                      .campaign_information && (
                                      <a
                                        className="info_popover"
                                        data-trigger="hover"
                                        href="javascript:"
                                        data-toggle="popover"
                                        img={campaign.campaign_image}
                                        content={
                                          campaign.bonus_campaign
                                            .campaign_information
                                        }
                                      >
                                        <i
                                          className="fa fa-info-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                  </label>
                                  <div className="clearfix"></div>
                                </div>
                              ))}
                              <div className="campaign">
                                <input
                                  id="no_bonus"
                                  checked={bonusCampaign === "no_bonus"}
                                  onChange={() => setBonusCampaign("no_bonus")}
                                  type="radio"
                                  value="no_bonus"
                                />
                                <label htmlFor="no_bonus" className="check_box">
                                  <i className="fas fa-check"></i>
                                </label>{" "}
                                <label
                                  htmlFor="no_bonus"
                                  className="campaign_label"
                                >
                                  {__("No Bonus")}
                                </label>
                              </div>
                            </>
                          ) : (
                            <div className="campaign">
                              <input
                                id="no_bonus"
                                checked={true}
                                type="radio"
                                onChange={() => setBonusCampaign("no_bonus")}
                              />
                              <label htmlFor="no_bonus" className="check_box">
                                <i className="fas fa-check"></i>
                              </label>
                              {userDetails.acceptsBonus ? (
                                <>
                                  <label
                                    htmlFor="no_bonus"
                                    className="campaign_label"
                                  >
                                    {__("No bonus available")}
                                  </label>
                                </>
                              ) : (
                                <label
                                  htmlFor="no_bonus"
                                  className="campaign_label"
                                >
                                  {__("You have chosen not to accept bonus")}
                                </label>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form_error ubonus_campaign"></div>
                      <div className="reg-submit">
                        <button type="submit" className=" common_brown_button">
                          {__("Continue")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={deleteBonusModalOpen}
              onHide={toggleDeleteBonusModalOpen}
            >
              <Modal.Header>
                <h5 className="modal-title">
                  {__("Are you sure to delete your bonus balance?")}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={toggleDeleteBonusModalOpen}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <center>
                  <button
                    type="button"
                    className="common_red_button"
                    onClick={toggleDeleteBonusModalOpen}
                  >
                    {__("No")}
                  </button>{" "}
                  <button
                    type="button"
                    type="button"
                    className="common_brown_button"
                    onClick={doDeleteBonuses}
                  >
                    {__("Yes")}
                  </button>
                </center>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <>
            <div className="container">
              <div className="row xbit_deposit_final_container">
                <div className="col-md-6 offset-md-3">
                  <div className="deposit_qr_area">
                    <h1 className="main_heading">{__("Final step of deposit")}</h1>
                    <div className="qr_container">
                      {isMobileDevice ? (
                        <a
                          href={depositFinalData.mobile_wallet}
                          target="_blank"
                        >
                          <img alt="" src={depositFinalData.paymentUriQrCode} />
                        </a>
                      ) : (
                        <img alt="" src={depositFinalData.paymentUriQrCode} />
                      )}
                    </div>
                    <div className="star_info">
                      **{" "}
                      {__(
                        "To deposit please scan the above QR Code with your Counos Wallet App"
                      )}
                      <br />
                      <a className=" readCarefullyModalCall" href="javascript:">
                        **{__("Read Carefully")}**
                      </a>
                    </div>

                    <div className="deposit_detils">
                      <h4>{__("Payment Details")}</h4>

                      <div className="single_details">
                        <label>
                          <b>{__("Selected Coin")}:</b>
                        </label>{" "}
                        {depositFinalData.selected_coin}
                      </div>
                      <div className="single_details">
                        <label>
                          <b>{__("Transaction ID")}:</b>
                        </label>{" "}
                        {depositFinalData.transaction_id}
                      </div>
                      <div className="single_details">
                        <label>
                          <b>{__("Total Amount")}:</b>
                        </label>{" "}
                        {depositFinalData.main_amount}
                      </div>
                      <div className="single_details">
                        <label>
                          <b>{__("Expected Amount")}:</b>
                        </label>{" "}
                        {depositFinalData.expected_amount}{" "}
                        {depositFinalData.selected_coin} -{" "}
                        {depositFinalData.selected_ticker}
                      </div>
                      <div className="single_details">
                        <label>
                          <b>{__("Expected Confirmations")}:</b>{" "}
                          <span className="expetedConfirmations">
                            {depositFinalData.expectedConfirmations}
                          </span>
                        </label>
                      </div>
                      <div className="single_details">
                        <label>
                          <b>{__("Reached Confirmations")}:</b>{" "}
                          <span className="reachedConfirmations">
                            {depositFinalData.paymentConfirmations}
                          </span>
                        </label>
                      </div>
                      <div className="single_details">
                        <label>
                          <b>{__("Wallet Address")}: </b>
                        </label>{" "}
                        <input
                          type="text"
                          readonly
                          value={depositFinalData.orderAddress}
                          id="orderAddress"
                        />
                        <i className="copy_order_address fas fa-copy"></i>
                      </div>

                      <div className="deposit_info">
                        {__(
                          "You can also use the above details to deposit the expected amount to the defined address. If you visit the page using your mobile device you can click on QR code and deposit by Counos Wallet. On the other hand you can use Counos Wallet APP to scan the QR and pay."
                        )}
                      </div>

                      <div className="app_link_area">
                        <div className="down_arrow">
                          <i className="fas fa-angle-down"></i>
                        </div>
                        <a
                          className="appstore"
                          target="_blank"
                          href="https://apps.apple.com/ch/app/counos-mobile-wallet/id1408806845?l=en"
                        >
                          <img
                            alt=""
                            src={
                              require("assets/images/common/appstore.svg")
                                .default
                            }
                          />
                        </a>
                        <a
                          className="googleplay"
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.counos"
                        >
                          <img
                            alt=""
                            src={
                              require("assets/images/common/googleplay.svg")
                                .default
                            }
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="footer_text_area">
                    <p className="star_info">
                      **
                      {__(
                        "Based on selected Coin, a QR Code will be generated. Then you can scan it by your Counos wallet. When your payment has been done on wallet, just return to this page and click on Continue button. While your payment gets enough confirmation, the payment will be mark as Paid and your account balance will be changed."
                      )}
                    </p>
                    <Link
                      className="common_brown_button"
                      to="/history/transactions"
                    >
                      {__("Continue")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              show={readCarefullyModalOpen}
              onHide={toggleReadCarefullyModal}
            >
              <Modal.Header>
                <h5 className="modal-title">{__("Read Carefully")}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={toggleReadCarefullyModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="read_cearfully_content">
                  <ul>
                    <li>
                      {__(
                        "To charge your account with cryptocurrencies, you need to specify the amount and type of cryptocurrency in the system. Each time, given the live price of the chosen cryptocurrency, a new payment request is created."
                      )}
                      <br />
                      {__(
                        "This payment request will include an address, a usage bar, and the calculated amount."
                      )}
                    </li>
                    <li>
                      {__("You cannot use an address twice. ")}
                      <br />
                      {__(
                        "Payments made outside the usual process of the system will be lost and not counted toward your balance."
                      )}
                    </li>
                    <li>
                      {__(
                        "The payment needs to be made in the exact amount and to the exact same address."
                      )}
                      <br />
                      {__(
                        "Sending a more or less amount and to a different address will result in your payment being lost and it will not be counted toward your balance."
                      )}
                    </li>
                    <li>
                      {__(
                        "In order for your payment to be counted toward your balance, it needs to receive enough number of confirmations."
                      )}
                      <br />
                      {__(
                        "Depending on the speed of different networks, this can take up to 1 hour. So, please be patient. If after 2 hours, your payment is not added to your balance, please contact support."
                      )}
                    </li>
                    <li>
                      {__(
                        "Full liability and responsibility for all the payments made outside of the process explained above is on the customer alone, and the website will bear no liability or responsibility for payments made outside of due process."
                      )}
                    </li>
                  </ul>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </AccountPagesTemplate>
    </>
  );
}
