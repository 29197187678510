import React from "react";

export default function CheckBox({ children, type, ...rest }) {
  const newType = type ? type : "checkbox";

  return (
    <div className="checking">
      <div className="checkbox_with_text">
        <div className="checkbox_container">
          <input type={newType} {...rest} />
        </div>
        <div>{children}</div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}
