import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import config from "configs/config";
import InputBox from "elements/InputBox";
import { Accordion } from "react-bootstrap";
import { __ } from "services/Translator";

export default function Contact(props) {
  const params = useParams();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const updateForm = (value, field) => {
    if (isAuthenticated && (field === "email" || field === "name")) {
      return false;
    }
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setForm({
        ...form,
        email: userDetails.email,
        name: userDetails.first_name + " " + userDetails.last_name,
      });
    }
  }, [userDetails]);

  const selectedTab = params.type;

  const [launchUrl, setLaunchUrl] = useState(config.liveChatUrl);

  const [faqs, setFaqs] = useState([]);
  const [selectedFaqCategory, setSelectedFaqCategory] = useState({ faqs: [] });

  const getFaqs = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-faqs");
    props.setSpinner(false);
    if (response.status === 200) {
      if (selectedFaqCategory.faqs.length === 0) {
        setSelectedFaqCategory(response.data[0]);
      }
      setFaqs(response.data);
    }
  };

  const [tickets, setTickets] = useState([]);
  const getTickets = async () => {
    props.setSpinner(true);
    var response = await api.get("/my-tickets");
    props.setSpinner(false);
    if (response.status === 200) {
      setTickets(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const sendMail = async () => {
    props.setSpinner(true);
    var response = await api.post("/create-ticket", form);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setForm({ ...form, subject: "", message: "" });
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  useEffect(() => {
    if (selectedTab === "faq") {
      getFaqs();
    } else if (selectedTab === "tickets") {
      getTickets();
    }
    props.setBreadcrumb([
      {
        name: __("Support"),
        link: "/support/faq",
      },
      {
        name:
          selectedTab === "faq"
            ? __("FAQ")
            : selectedTab === "contact"
            ? __("Contact Us")
            : __("My Tickets"),
      },
    ]);
  }, [selectedTab]);

  const onWebViewLoaded = (web) => {
    props.setSpinner(false);
  };
  const onWebViewLoadedIframe = () => {
    props.setSpinner(false);
  };

  const accordionToggled = (lastOpenItem) => {
    if (lastOpenItem !== null) {
      lastOpenItem = parseInt(lastOpenItem);
    }
  };

  return (
    <>
      <section className="content_section container">
        <div className="cms_content_bg">
          <div className="support_container support_content_area">
            <div className="common_heading_area">
              <h1 className="common_title pull-left">{__("Support")}</h1>

              <ul className="common_tab_menu pull-right">
                <li>
                  <Link
                    className={selectedTab === "faq" ? "active" : ""}
                    to="/support/faq"
                  >
                    {__("FAQ")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/support/contact"
                    className={selectedTab === "contact" ? "active" : ""}
                  >
                    {__("Contact Us")}
                  </Link>
                </li>

                {isAuthenticated && (
                  <li className="">
                    <Link
                      className={selectedTab === "tickets" ? "active" : ""}
                      to="/support/tickets"
                    >
                      {__("My Tickets")}
                    </Link>
                  </li>
                )}
              </ul>
              <div className="clearfix"></div>
            </div>

            <div className="alert alert-success" role="alert"></div>

            <div className="support_content">
              {selectedTab === "faq" && (
                <div className="faq_content_area">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="content_left_menu">
                        <ul className="support_menu">
                          {faqs.map((faqCategory, index) => (
                            <li key={index}>
                              <a
                                className={
                                  selectedFaqCategory.category ===
                                  faqCategory.category
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  setSelectedFaqCategory(faqCategory)
                                }
                                href="#"
                              >
                                <span className="left_menu_icon">
                                  <i className="fas fa-user"></i>
                                </span>
                                <span className="left_menu_text">
                                  {faqCategory.category}
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="content_right">
                        <div className="faq faq_area">
                          <h4 className="faq__title">{__("FAQ")}</h4>
                          <Accordion>
                            {selectedFaqCategory.faqs.map((faq, index2) => (
                              <Accordion.Item
                                key={index2}
                                eventKey={index2}
                                className="faq-item"
                              >
                                <Accordion.Header className="faq__question">
                                  {faq.title} <i></i>
                                </Accordion.Header>
                                <Accordion.Body className="faq__answer">
                                  {faq.description}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              )}

              {selectedTab === "contact" && (
                <div className="contact_us_area">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="reg-field col-sm-6">
                          <InputBox
                            type="text"
                            className="form-control required_field"
                            readOnly={isAuthenticated}
                            required={true}
                            value={form.name}
                            onChange={(value) => updateForm(value, "name")}
                            placeholder={__("Full Name")}
                          />
                        </div>
                        <div className="reg-field col-sm-6">
                          <InputBox
                            type="email"
                            className="form-control required_field"
                            readOnly={isAuthenticated}
                            required={true}
                            value={form.email}
                            onChange={(value) => updateForm(value, "email")}
                            placeholder={__("Email")}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="reg-field col-sm-12">
                          <InputBox
                            required
                            type="text"
                            className="form-control required_field"
                            value={form.subject}
                            onChange={(value) => updateForm(value, "subject")}
                            placeholder={__("Subject")}
                          />
                        </div>
                      </div>

                      <div className="reg-field">
                        <textarea
                          required
                          name="message"
                          className="form-control required_field"
                          rows="10"
                          value={form.message}
                          onChange={(ev) =>
                            updateForm(ev.target.value, "message")
                          }
                          placeholder={__("Message")}
                        ></textarea>
                      </div>
                      <input
                        type="hidden"
                        name="g-recaptcha-response"
                        value=""
                      />
                      <div className="reg-submit">
                        <button
                          onClick={sendMail}
                          className="common_brown_button recap_submit_button"
                        >
                          {__("Submit")}
                        </button>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              )}

              {selectedTab === "tickets" && (
                <ul className="tickets_list">
                  {!!tickets.total ? (
                    <>
                      {tickets.openTickets.map((item, index) => (
                        <li className="single_ticket" id={"ticket_" + item.id}>
                          <Link to={"/support/ticket/" + item.id}>
                            <h3 className="ticket_heading">
                              {__("Ticket")}#{item.id}-{item.title}
                            </h3>
                          </Link>
                          <div className="ticket_date">{item.created_at}</div>
                        </li>
                      ))}

                      {tickets.solvedTickets.map((item, index) => (
                        <li className="single_ticket" id={"ticket_" + item.id}>
                          <Link to={"/support/ticket/" + item.id}>
                            <h3 className="ticket_heading">
                              {__("Ticket")}#{item.id}-{item.title}
                            </h3>
                          </Link>
                          <div className="ticket_date">{item.created_at}</div>
                        </li>
                      ))}
                    </>
                  ) : (
                    <li className="single_ticket">
                      {__("No tickets were created yet.")}
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
