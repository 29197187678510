import api from "services/api";
import ls from "services/ls";

class moneyFormatter {
  async getConversionRates() {
    var rates = await api.get("/get-latest-exchange-rates");
    if (rates.status === 200) {
      ls.set("conversionRates", rates.data);
      return rates.data;
    } else {
      console.log("/get-latest-exchange-rates failed");
      return null;
    }
  }

  convertCurrencyToFiat(amount, currencyFrom, currencyTo) {
    var iconsMap = {
      USD: "$",
      EUR: "€",
    };
    var pair = currencyFrom + currencyTo;

    var rates = ls.get("conversionRates");
    if (!rates || !rates[pair]) {
      return "...";
    }
    return this.balanceFormat(
      parseFloat(rates[pair].rate) * amount,
      currencyTo
    );
  }

  balanceFormat(amount) {
    return parseFloat(amount).toFixed(2);
  }

  currencyWithIcon(currency) {
    return (
      <>
        {this.currencyIcon(currency)}{" "}
        <span className="currency_name">{currency}</span>
      </>
    );
  }

  currencyName(currency) {
    var namesMap = {
      BTC: "Bitcoin",
      ETH: "Ethereum",
      USDTE: "Tether (Erc-20)",
      DOGE: "Dogecoin",
      LTC: "Litecoin",
      USD: "US Dollar",
      EUR: "Euro",
    };
    return namesMap[currency];
  }

  currencyFullWithIcon(currency) {
    return (
      <>
        {this.currencyIcon(currency)}{" "}
        <span className="amount">{this.currencyName(currency)}</span>
      </>
    );
  }

  currencyIcon(currency) {
    var iconsMap = {
      BTC: "btc",
      ETH: "eth",
      USDTE: "usdt",
      DOGE: "doge",
      LTC: "ltc",
      USD: "usd",
      EUR: "eur",
    };

    return (
      <span className="currency_icon">
        <svg className="svg_icon">
          <use xlinkHref={"/icons.svg#icon-currency-" + iconsMap[currency]} />
        </svg>
      </span>
    );
  }
}
export default new moneyFormatter();
