import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import AppContext from "contexts/AppContext";
import ls from "services/ls";
import { __ } from "services/Translator";

export default function Terms(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});

  const getResults = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "terms_conditions",
    });
    props.setBreadcrumb([]);
    if (response.status === 200) {
      setPageContent(response.data.content_data);
      props.setBreadcrumb([{ name: response.data.content_data.page_title }]);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <section className="content_section container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="common_heading_area">
                <h1 className="common_title">{pageContent?.page_title}</h1>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(pageContent.content),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
