import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import moment from "moment";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import UserLevelMedals from "elements/UserLevelMedals";
import ls from "services/ls";
import { __ } from "services/Translator";

import { Button, Alert, Overlay, Tooltip } from "react-bootstrap";
import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";

import AccountPagesTemplate from "elements/AccountPagesTemplate";

export default function Profile(props) {
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const accountProgress = props.accountProgress;
  const getAccountProgress = props.getAccountProgress;
  const setSpinner = props.setSpinner;

  const genders = [
    { label: __("Gender"), value: null },
    { label: __("Male"), value: "Male" },
    { label: __("Female"), value: "Female" },
    { label: __("Other"), value: "Other" },
  ];

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const currencies = [
    { label: __("Currency"), value: null },
    { label: "XBU", value: "USD" },
  ];
  const [phoneCodes, setPhoneCodes] = useState([]);

  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(
        response.data.map((val) => {
          return { label: val.nicename, value: val.code };
        })
      );
      setPhoneCodes(
        response.data.map((val) => {
          return {
            label: "+" + val.phonecode + " (" + val.code + ")",
            value: "+" + val.phonecode,
          };
        })
      );
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(
        response.data.map((val) => {
          return { label: val.language, value: val.code };
        })
      );
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const getProfile = async () => {
    setSpinner(true);
    var response = await api.get("/profile");
    setSpinner(false);
    if (response.status === 200) {
      setForm(response.data);
      setIsDataFetched(true);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const submitProfile = async (ev) => {
    ev.preventDefault();
    setSpinner(true);
    var response = await api.post("/profile/save", {
      oldPassword: form.oldPassword,
      password: form.password,
      repassword: form.repassword,
    });
    setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setForm({
        ...form,
        oldPassword: "",
        password: "",
        repassword: "",
      });
      setIsDataFetched(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    currency: "",
    language: "",
    phoneCode: "",
    phone: "",
  });

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getAllData = async (force) => {
    if (!isDataFetched || force) {
      setSpinner(true);
      await getCountries();
      await getLanguages();
      await getProfile();
      // setSpinner(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Personal Details") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Personal Details")}</div>

        <div className="user_level_area">
          <div className="current_medal_area">
            <UserLevelMedals labels={true} identity={form.identity} />
          </div>
          {form.identity < 6 && (
            <>
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: form.account_progress?.toString() + "%" }}
                  aria-valuenow={form.account_progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {form.account_progress}%
                </div>
              </div>

              <div className="target_medal_area">
                <UserLevelMedals
                  labels={true}
                  identity={form.target_identity}
                />
              </div>
            </>
          )}
        </div>

        <div className="myaccount_input_area">
          <form
            id="updateUserInfo"
            action=""
            onSubmit={submitProfile}
            method="post"
          >
            <div className="row">
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  readOnly={true}
                  placeholder={__("First Name")}
                  value={form.firstName}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  readOnly={true}
                  placeholder={__("Last Name")}
                  value={form.lastName}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="email"
                  readOnly={true}
                  placeholder={__("Your Email")}
                  value={form.email}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="number"
                  readOnly={true}
                  placeholder={__("Phone Number")}
                  value={form.phone}
                />
              </div>
              <div className="col-sm-6">
                <DatePicker
                  className="form-control"
                  placeholder="Date of Birth"
                  value={form.dateOfBirth}
                  minValue={moment()}
                  readOnly={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectBox
                  className="form-control"
                  placeholder="Gender"
                  minwidth={100}
                  value={form.gender}
                  items={genders}
                  readOnly={true}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  readOnly={true}
                  placeholder={__("Address")}
                  value={form.address}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  readOnly={true}
                  placeholder={__("City")}
                  value={form.city}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  readOnly={true}
                  placeholder={__("Zipcode")}
                  value={form.zip}
                />
              </div>
              <div className="col-sm-6">
                <SelectBox
                  value={form.country}
                  className="form-control"
                  minwidth={200}
                  placeholder={__("Country")}
                  items={countries}
                  readOnly={true}
                />
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="my_account_sub_heading">
                  {__("Account Details")}
                </div>
              </div>
              <div className="col-sm-6 d-none">
                <input
                  className="form-control "
                  type="text"
                  value={form.username}
                  readOnly={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectBox
                  value={form.currency}
                  className="form-control"
                  minwidth={200}
                  placeholder={__("Currency")}
                  items={currencies}
                  readOnly={true}
                />
              </div>
              <div className="col-sm-6 my_account_sub_heading">
                <input
                  className="form-control"
                  type="text"
                  value={form.pin ? form.pin : __("Your Identification Number")}
                  readOnly={true}
                />
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="my_account_sub_heading">
                  {__("Update Password")}
                </div>
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="Password"
                  onChange={(ev) => {
                    updateForm(ev.target.value, "oldPassword");
                  }}
                  value={form.oldPassword}
                  placeholder={__("Old Password")}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="Password"
                  onChange={(ev) => {
                    updateForm(ev.target.value, "password");
                  }}
                  value={form.password}
                  placeholder={__("New Password")}
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="Password"
                  onChange={(ev) => {
                    updateForm(ev.target.value, "repassword");
                  }}
                  value={form.repassword}
                  placeholder={__("Re-type Password")}
                />
              </div>
              <div className="clearfix"></div>
            </div>

            <button type="submit" className="common_brown_button">
              {__("Update Password")}
            </button>
          </form>
        </div>
      </AccountPagesTemplate>
    </>
  );
}
