import React, { useState, useEffect } from "react";
import ScrollTo from "elements/ScrollTo";
import auth from "services/auth";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import config from "configs/config";
import api from "services/api";
import { ToastContainer, Toast } from "react-bootstrap";
import Header from "elements/Header";
import Footer from "elements/Footer";
import Banner from "elements/Banner";
import Spinner from "elements/Spinner";
import { __ } from "services/Translator";

import {
  isMobile,
  isMobileByViewPort,
  cauculateLandScape,
} from "services/isMobile";

const FullLayout = ({ children, ...rest }) => {
  const [toasts, setToasts] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [dummySpinner, setDummySpinner] = useState(false);

  const location = useLocation();
  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] = useState(
    false
  );

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser());

  const showAlert = (title, message, isError, callback, delay) => {
    if (typeof message === "object") {
      var newToasts = Object.entries(message).map((msg, index) => {
        return {
          show: true,
          title: msg[0] + " error",
          message: msg[1],
          isError: isError,
          className: isError ? "warning" : "success",
          callback: callback,
          delay:
            typeof delay === "number" ? delay : config.debug ? 15000 : 3000,
        };
      });
      setToasts([...toasts, ...newToasts]);
    } else {
      var toast = {
        show: true,
        title: title,
        message: message,
        isError: isError,
        className: isError ? "warning" : "success",
        callback: callback,
        delay: typeof delay === "number" ? delay : config.debug ? 15000 : 3000,
      };
      setToasts([...toasts, toast]);
    }
    // if (typeof callback === "function") {
    //   callback();
    // }
  };

  const removeAlert = (index) => {
    var callback = toasts[index].callback;
    // alert(typeof callback);
    var toastsClone = [...toasts];
    toastsClone[index].show = false;
    setToasts(toastsClone);
    if (typeof callback === "function") {
      callback();
    }
  };

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    ls.set("conversionRates", response.data.rates);
    setUserBalance(response.data);
  };

  const [accountProgress, setAccountProgress] = useState({});
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(response.data);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());

    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  useEffect(() => {
    setDummySpinner(true);
    setTimeout(() => {
      setDummySpinner(false);
    }, 500);
  }, [location.pathname]);

  const [displayedModal, setDisplayedModal] = useState(null);

  const [breadcrumb, setBreadcrumb] = useState([]);

  const passableParams = {
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    setSpinner,
    userDetails,
    userBalance,
    getUserBalance,
    accountProgress,
    getAccountProgress,
    displayedModal,
    setDisplayedModal,
    breadcrumb,
    setBreadcrumb,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  return (
    <>
      {(spinner || dummySpinner) && <Spinner />}
      <ToastContainer position="middle-end" className="p-3">
        {toasts.map((toast, index) => (
          <React.Fragment key={index}>
            {toast.show && (
              <Toast
                delay={toast.delay}
                autohide
                bg={toast.className}
                onClose={() => removeAlert(index)}
                key={index}
              >
                <Toast.Header>
                  <strong className="me-auto">
                    {toast.title
                      ? toast.title
                      : toast.isError
                      ? "Warning!!"
                      : "Message"}
                  </strong>
                  <small></small>
                </Toast.Header>

                <Toast.Body>{toast.message}</Toast.Body>
              </Toast>
            )}
          </React.Fragment>
        ))}
      </ToastContainer>
      <div id="main">
        <Header {...passableParams} />
        <div className="main_section_area">
          {location.pathname === "/" && !isAuthenticated && (
            <Banner {...passableParams} />
          )}
          <main>{childrenWithProps}</main>
        </div>
        <Footer {...passableParams} />
      </div>
    </>
  );
};

export default FullLayout;
