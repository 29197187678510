import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { __ } from "services/Translator";
import ResendActivationEmail from 'elements/ResendActivationEmail';

export default function RegistrationComplete(props) {
  useEffect(() => {
    props.setBreadcrumb([{ name: __("Activate Your Account") }]);
  }, []);

  const params = useParams();

  return (
    <>
      <section className="content_section container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="registration_complete_area">
                <h1 className="common_title">{__("Activate Your Account")}</h1>
                <div className="clearfix"></div>
                <h4>{__("Please acitivate your account")}</h4>
                <p>
                  <b>
                    {__(
                      "Please use the form below to re-send an activation code to your email."
                    )}
                  </b>
                  <br />
                  &nbsp;
                  <br />
                  {__(
                    "After submitting the form, if you do not receive the email within a few minutes, please check your email spam folder to check if the message did not end up there. You can have the email send again by using the field below."
                  )}
                </p>
                <ResendActivationEmail email={params.email} {...props} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
