import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import config from "configs/config";
import InputBox from "elements/InputBox";
import SelectBox from "elements/SelectBox";
import LanguageSelector from "elements/LanguageSelector";
import { Accordion } from "react-bootstrap";
import { __ } from "services/Translator";

export default function AgentSignup(props) {
  const params = useParams();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [ipCountry, setIpCountry] = useState({});

  const emptyForm = {
    agent_name: "",
    agent_surname: "",
    agent_email: "",
    agent_phone: "",
    agent_birth_date: {
      year: (new Date().getFullYear() - 18).toString(),
      month: (new Date().getMonth() + 1).toString().padStart(2, "0"),
      day: new Date().getDate().toString().padStart(2, "0"),
    },
    agent_gender: "Male",
    agent_street_addr: "",
    agent_state: "",
    agent_zip: "",
    agent_language: "en",
    agent_currency: "USD",
    agent_country: ipCountry.ip_country,
    agent_password: "",
    agent_confirm_password: "",
    "g-recaptcha-response": "",
  };

  const [form, setForm] = useState(emptyForm);
  const [errors, setErrors] = useState({});

  const updateForm = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(response.data);
    }
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
  };

  const updateCheckBox = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.checked;
    updateForm(field, value);
  };

  const months = [
    __("Month"),
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July ",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const genders = [
    { label: "Gender", value: null },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const updateDateOfBirth = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var dob = { ...form.agent_birth_date, [field]: value };
    updateForm("agent_birth_date", dob);
  };

  const doRegister = async () => {
    var formClone = JSON.parse(JSON.stringify(form));
    formClone.agent_birth_date =
      form.agent_birth_date.year +
      "-" +
      form.agent_birth_date.month +
      "-" +
      form.agent_birth_date.day;
    props.setSpinner(true);
    setErrors({});
    var response = await api.post("/agents/signup", formClone);
    props.setSpinner(false);
    if (response.status === 200) {
      setForm(emptyForm);
      props.showAlert("Success", response.data.message);
    } else {
      if (response.data.validationErrors) {
        var errors = response.data.validationErrors;
        setErrors(errors);
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  const getIpDetails = async () => {
    var response = await api.get("/ip-details");
    if (response.status === 200) {
      setIpCountry(response.data);
      updateForm("country", response.data.ip_country);
      updateForm("phoneCode", response.data.dialing_code);
    }
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Agent Sign Up") }]);
    getCountries();
    getIpDetails();
  }, []);

  return (
    <>
      <section className="content_section container">
        <div className="registration_area agent_signup_area">
          <h1 className="common_title">{__("Xbit Gaming Platform Agent")}</h1>
          <p>
            You can apply to become an agent in the Xbit Gaming Platform and
            earn money by inviting people to register and play in this gaming
            platform.{" "}
          </p>
          <p>
            When you become an agent you will have your own account and back
            office, where you can actually check on all the users you have
            invited to register and play.{" "}
          </p>
          <p>
            You can also see exactly how much revenue has been generated for the
            platform. And you will receive 20 percent of the revenue as your
            agent fee.
          </p>
          <p>
            You will have your own exclusive link for inviting users to register
            at the Xbit Gaming Platform. In addition, you can track the users
            you have brought to the gaming platform. Those users all come under
            your account forever. And you will always get commission from the
            games they play. It’s like having your own casino.
          </p>
          <p>
            Become an agent for the Xbit Gaming Platform today and start earning
            a passive income.
          </p>

          <div className="agent_register_form">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_name"
                  className="form-control"
                  placeholder={__("First Name")}
                  value={form.agent_name}
                  onChange={updateInput}
                />
                <div className="error_msg">{errors.agent_name}</div>
                <br />
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_surname"
                  value={form.agent_surname}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("Last Name")}
                />
                <div className="error_msg">{errors.agent_surname}</div>
                <br />
              </div>
              <div className="col-md-4">
                <input
                  type="email"
                  name="agent_email"
                  value={form.agent_email}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("email")}
                />
                <div className="error_msg">{errors.agent_email}</div>
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_phone"
                  value={form.agent_phone}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("Phone")}
                />
                <div className="error_msg">{errors.agent_phone}</div>
                <br />
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-4">
                    <select
                      required
                      name="day"
                      value={form.agent_birth_date.day}
                      onChange={updateDateOfBirth}
                      className="day_input form-control"
                    >
                      <option value="">{__("Day")}</option>
                      {Array.from({ length: 31 }, (v, i) => i + 1).map(
                        (day) => (
                          <option
                            key={day}
                            value={day.toString().padStart(2, "0")}
                          >
                            {day.toString().padStart(2, "0")}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="month"
                      required
                      value={form.agent_birth_date.month}
                      onChange={updateDateOfBirth}
                      className=" month_input form-control"
                    >
                      {Array.from({ length: 13 }, (v, i) => i).map((item) => (
                        <option
                          key={item}
                          value={item.toString().padStart(2, "0")}
                        >
                          {months[item]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="year"
                      required
                      value={form.agent_birth_date.year}
                      onChange={updateDateOfBirth}
                      className=" year_input form-control"
                    >
                      <option value="">{__("Year")}</option>
                      {Array.from(
                        { length: 70 },
                        (v, i) => new Date().getFullYear() - 18 - i
                      ).map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="error_msg">{errors.agent_birth_date}</div>
              </div>

              <div className="col-md-4">
                <SelectBox
                  className="form-control"
                  value={form.agent_gender}
                  items={genders}
                  onChange={(value) => updateForm("agent_gender", value)}
                />
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_street_addr"
                  value={form.agent_street_addr}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("Address")}
                />
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_state"
                  value={form.agent_state}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("State")}
                />
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_city"
                  value={form.agent_city}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("City")}
                />
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="text"
                  name="agent_zip"
                  value={form.agent_zip}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("Zip")}
                />
                <br />
              </div>

              <div className="col-md-4">
                <select
                  name="agent_country"
                  className="form-control"
                  value={form.country}
                  onChange={updateInput}
                >
                  {countries.map((item, index) => {
                    if (item.code === "IR") {
                      return null;
                    } else {
                      return (
                        <option key={index} value={item.code}>
                          {item.nicename}
                        </option>
                      );
                    }
                  })}
                </select>
                <br />
              </div>
              <div className="col-md-4">
                <LanguageSelector
                  onChange={(value) => updateForm("agent_language", value)}
                  value={form.agent_language}
                  className="form-control"
                ></LanguageSelector>
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="password"
                  name="agent_password"
                  value={form.agent_password}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("Password")}
                />
                <div className="error_msg">{errors.agent_password}</div>
                <br />
              </div>
              <div className="col-md-4">
                <input
                  type="password"
                  name="agent_confirm_password"
                  value={form.agent_confirm_password}
                  onChange={updateInput}
                  className="form-control"
                  placeholder={__("Confirm Password")}
                />
                <div className="error_msg">{errors.agent_confirm_password}</div>
                <br />
              </div>

              <div className="col-md-4">
                <input
                  type="hidden"
                  name="g-recaptcha-response"
                  onChange={updateInput}
                  value={form["g-recaptcha-response"]}
                />
                <input
                  type="button"
                  onClick={doRegister}
                  className="common_brown_button  recap_submit_button register_action"
                  value={__("Create Account")}
                />
                <div className="error_msg">
                  {errors["g-recaptcha-response"]}
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
