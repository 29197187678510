import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import ls from "services/ls";
import { __ } from "services/Translator";
import { Button, Alert, Overlay, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";

import ProfileSideMenu from "elements/ProfileSideMenu";

export default function AccountPagesTemplate(props) {
  const [visibleOnMobile, setVisibleOnMobile] = useState(false);

  const toggleVisibleOnMobile = () => {
    setVisibleOnMobile(!visibleOnMobile);
  };

  return (
    <>
      <section className="content_section container">
        <div className="my_account_container">
          <div className="common_heading_area">
            <h1 className="common_title">{__("My Account")}</h1>

            <a
              className="show_hide_account_menu"
              href="#"
              onClick={toggleVisibleOnMobile}
            >
              <i className="fas fa-bars"></i>
            </a>
            <div className="clearfix"></div>
          </div>
          <div className="my_account_content">
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <div className="content_left_menu">
                  <ProfileSideMenu visibleOnMobile={visibleOnMobile} />
                </div>
              </div>
              <div className="col-md-9 col-sm-8" style={{overflowX: 'scroll'}}>
                <div className="content_right my_account_main_content">
                  {props.children ? (
                    props.children
                  ) : (
                    <div className="loader_area">
                      <img
                        alt="ajax-loader.gif"
                        src={
                          require("assets/images/common/ajax-loader.gif")
                            .default
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
