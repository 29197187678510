import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import { __ } from "services/Translator";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";

import AccountPagesTemplate from "elements/AccountPagesTemplate";

export default function Withdraw(props) {
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalWallet, setWithdrawalWallet] = useState("");
  const [withdrawalEmail, setWithdrawalEmail] = useState("");
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null);
  const [withdrawalDetails, setWithdrawalDetails] = useState({});
  const [withdrawalConfirmationModal, setWithdrawalConfirmationModal] =
    useState(false);
  const [withdrawalError, setWithdrawalError] = useState(null);

  const getWithdrawalMethods = async () => {
    // if (withdrawalAmount) {
    props.setSpinner(true);
    var response = await api.post("/withdraw/methods", {
      amount: withdrawalAmount,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setWithdrawalMethods(response.data.methods);
      if (
        !response.data.methods.filter(
          (method) => method.method_id === withdrawalMethod
        ).length
      ) {
        setWithdrawalMethod(null);
      }
      setWithdrawalDetails(response.data.details);
    }
    // }
  };

  const isWithdrawPossible = async () => {
    if (!withdrawalMethod) {
      setWithdrawalError(__("Please select a withdrwal method"));
    } else if (!withdrawalWallet && isWalletAddressRequired()) {
      setWithdrawalError(__("Please enter your wallet address"));
    } else {
      var response = await api.post("/is-withdraw-possible", {
        method: withdrawalMethod,
        accNumber: withdrawalWallet,
      });
      if (response.status === 200) {
        setWithdrawalError(null);
      } else {
        setWithdrawalError(response.data.message);
      }
    }
  };

  const showWithdrwalConfirmation = (event) => {
    event.preventDefault();
    isWithdrawPossible();
    setWithdrawalConfirmationModal(true);
  };
  const closeWithdrwalConfirmation = () => {
    setWithdrawalConfirmationModal(false);
  };

  const submitWithdraw = async () => {
    if (withdrawalError) {
      return;
    }
    props.setSpinner(true);
    var response = await api.post("/withdraw", {
      amount: withdrawalAmount,
      method: withdrawalMethod,
      accNumber: withdrawalWallet,
      email: withdrawalEmail,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "success") {
        props.showAlert("Success!!", response.data.message);
        setWithdrawalAmount("");
        setWithdrawalMethod(null);
        setWithdrawalWallet("");
        getWithdrawalMethods();
        closeWithdrwalConfirmation();
        props.getUserBalance();
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const isWalletAddressRequired = (method) => {
    var required = false;
    if (!method) {
      required = true;
    } else if (method === "SKRILL:WLT") {
      required = true;
    } else if (method.indexOf("CRYPTOPAY:") === 0) {
      required = true;
    } else if (method == "Interac") {
      required = true;
    } else if (method.indexOf("Counos:") === 0) {
      required = true;
    } else if (method.indexOf("COINSPAID:") === 0) {
      required = true;
    }
    return required;
  };

  useEffect(() => {
    getWithdrawalMethods();
    // }, [withdrawalAmount]);
  }, []);

  useEffect(() => {
    props.setBreadcrumb([
      { name: __("Cashier"), link: "/cashier" },
      { name: __("Withdraw") },
    ]);
  }, []);

  //Withdrawal OTP functionality's here
  const [withdrawalOTPverificationModal, setwithdrawalOTPverificationModal] =
    useState(false);
  const [OTPValue, setOTPValue] = useState("");
  const closeWithdrawalOTPverificationModal = () => {
    setwithdrawalOTPverificationModal(false);
  };

  const sendWithdrawalOtp = async () => {
    if (withdrawalError) {
      return;
    }
    props.setSpinner(true);
    var response = await api.post("/send-withdrawal-otp", {
      withdrawalAmount: withdrawalAmount,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "success") {
        setWithdrawalConfirmationModal(false);
        setwithdrawalOTPverificationModal(true);
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const withdrawalOTPVerification = async () => {
    if (withdrawalError) {
      return;
    }
    props.setSpinner(true);
    var response = await api.post("/withdrawal-otp-verification", {
      otpCode: OTPValue,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "success") {
        setwithdrawalOTPverificationModal(false);
        submitWithdraw();
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Cashier")}</div>
        <p className="text">
          {__("Use the form below to withdraw funds from your account.")}
        </p>
        {__("Amount that can be withdrawn:")}{" "}
        <b>{withdrawalDetails.total_withdrawal_amount}</b> XBU (Counos X:{" "}
        <b>{withdrawalDetails.counos_x_withdrawal_amount}</b>, XBIT:{" "}
        <b>{withdrawalDetails.xbt_withdrawal_amount}</b>).
        <br />
        <br />
        <form
          method="post"
          id="withdrawForm"
          onSubmit={showWithdrwalConfirmation}
        >
          <div className="row">
            <div className="col-sm-12">
              <input
                type="number"
                value={withdrawalAmount}
                onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                className="form-control"
                placeholder={__("Amount")}
              />
              <input
                type="hidden"
                id="verifiedCode"
                className="form-control"
                name="verified_code"
              />
            </div>

            {!isWalletAddressRequired(withdrawalMethod) && (
              <div className="col-md-12 account_number_explanation">
                <br />
                <div className="alert alert-warning">
                  <b>
                    {__(
                      "Withdrawals will be sent to the same account used for deposit with this method."
                    )}
                  </b>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 deposit-methods">
              <div className="payment_method_list">
                <div className="banking_area">
                  <div className="banking_list_area">
                    <div className="small_info">
                      {__(
                        "Click on the 'i' icon within a logo for more information about a payment method."
                      )}
                    </div>

                    {withdrawalMethods.map((method, index) => (
                      <div className="single_banking e_wallet_method">
                        <input
                          id={"all_" + method.id}
                          onChange={() => setWithdrawalMethod(method.method_id)}
                          type="radio"
                          checked={method.method_id === withdrawalMethod}
                        />
                        <label htmlFor={"all_" + method.id}>
                          <ul>
                            <li className="payment_logo_area">
                              <a
                                target="_blank"
                                href="https://www.counos.io/CounosX"
                                className="icon_popover"
                              >
                                i
                              </a>
                              <span className="helper"></span>
                              <span className="payment_text_mobile">
                                <span className="helper"></span>
                                <span className="method_logo_text">
                                  {__("Method Logo")}
                                </span>
                              </span>
                              <div
                                className="method_logo"
                                style={{
                                  backgroundImage: "url(" + method.logo + ")",
                                }}
                              ></div>
                              <div className="clearfix"></div>
                            </li>
                            <li className="payment_method_name">
                              <span className="helper"></span>
                              <span className="payment_text_mobile">
                                {__("Method Name")}
                              </span>
                              <span className="payment_content">
                                {" "}
                                {method.method}
                              </span>
                              <div className="clearfix"></div>
                            </li>
                            <li className="min_payment_amount">
                              <span className="payment_text_mobile">
                                {__("Limit Amount")}
                              </span>
                              <span className="payment_content">
                                {__("MIN")} {method.min_withdrawal} XBU (1 XBU{" "}
                                {__("Equivalent to 1 USD")})
                                <br />
                                <i>
                                  **{method.withdrawal_fee}%{" "}
                                  {__(
                                    "Transaction fee will be deducted from your withdrawal amount."
                                  )}
                                </i>
                              </span>
                              <div className="clearfix"></div>
                            </li>
                          </ul>
                        </label>
                        <label
                          htmlFor={"all_" + method.id}
                          className="payment_check_mark"
                        >
                          <i className="fas fa-check selected_method_icon"></i>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {isWalletAddressRequired(withdrawalMethod) && (
              <div className="col-sm-12 account_number">
                <input
                  required
                  type="text"
                  value={withdrawalWallet.toString()}
                  onChange={(ev) => setWithdrawalWallet(ev.target.value)}
                  className="form-control acc_number"
                  placeholder={__("Wallet Address")}
                />
                <br />
              </div>
            )}

            <div className="col-sm-12 account_number">
              <input
                readOnly={true}
                type="email"
                value={userDetails.email}
                className="form-control email_address"
                placeholder={__("User Email")}
              />
              <br />
            </div>
            <div className="col-12">
              <a
                href="#"
                onClick={showWithdrwalConfirmation}
                className="withdraw_btn common_brown_button"
              >
                {__("Submit")}
              </a>
            </div>
          </div>
        </form>
        <p className="text">
          <br />
          &nbsp;
          <br />
          {__("Total locked bonus amount:")}{" "}
          <b>{withdrawalDetails.locked_bonus_amount}</b> XBB
          <br />
          &nbsp;
          <br />
          {__("Total bet turnover:")} <b>{withdrawalDetails.bet_turnover}</b>{" "}
          XBB
          <br />
          &nbsp;
          <br />
          {__("Total bet amount necessary to unlock remaining bonus:")}{" "}
          <b>{withdrawalDetails.amount_2_unlock}</b> XBB
        </p>
        <Modal
          show={withdrawalConfirmationModal}
          onHide={closeWithdrwalConfirmation}
          id="popup-withdraw"
          className="modal withdraw_popup"
        >
          <Modal.Header>
            <h5 className="modal-title">{__("Withdrawal Confirmation")}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeWithdrwalConfirmation}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            {withdrawalError ? (
              <p>{withdrawalError}</p>
            ) : (
              <p>
                {__(
                  "Are you sure you want to make a withdrawal? Any remaining Bonus Balance or Free Spins will be forfeited."
                )}{" "}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!withdrawalError && (
              // <button
              //   type="button"
              //   className="common_brown_button ConfirmBtn"
              //   onClick={submitWithdraw}
              // >
              //   {__("confirm")}
              // </button>

              <button
                type="button"
                className="common_brown_button ConfirmBtn"
                onClick={sendWithdrawalOtp}
              >
                {__("confirm")}
              </button>
            )}
            <button
              type="button"
              className="common_red_button"
              onClick={closeWithdrwalConfirmation}
            >
              {__("Close")}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={withdrawalOTPverificationModal}
          onHide={closeWithdrawalOTPverificationModal}
          id="popup-withdraw"
          className="modal withdraw_popup"
        >
          <Modal.Header>
            <h5 className="modal-title">{__("Withdrawal OTP verification")}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setwithdrawalOTPverificationModal(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            {withdrawalError ? (
              <p>{withdrawalError}</p>
            ) : (
              <p>
                {__(
                  "A withdrawal verification OTP code sent to your email address. Please check your email and verify your withdrawal. The OTP will be expired in 5 minutes."
                )}{" "}
              </p>
            )}

            <input
              required
              type="number"
              class="form-control withdrawal_otp_verification"
              name="OTPValue"
              placeholder={__("Withdrawal OTP Verification")}
              value={OTPValue}
              onChange={(ev) => setOTPValue(ev.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            {!withdrawalError && (
              // <button
              //   type="button"
              //   className="common_brown_button ConfirmBtn"
              //   onClick={submitWithdraw}
              // >
              //   {__("confirm")}
              // </button>

              <button
                type="button"
                className="common_brown_button ConfirmBtn"
                onClick={withdrawalOTPVerification}
              >
                {__("confirm")}
              </button>
            )}
            <button
              type="button"
              className="common_red_button"
              onClick={() => {
                setwithdrawalOTPverificationModal(false)
              }}
            >
              {__("Close")}
            </button>
          </Modal.Footer>
        </Modal>
        
      </AccountPagesTemplate>
    </>
  );
}
