import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "services/auth";
import Spinner from "elements/Spinner";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { __ } from "services/Translator";
import LanguageSelector from "elements/LanguageSelector";
import RegInput from "elements/RegInput";
import RegCampaigns from "elements/RegCampaigns";
// import passwordValidate from "services/passwordValidate";
import {
  isMobile,
  isMobileByViewPort,
  cauculateLandScape,
} from "services/isMobile";

// import SocialLogin from "elementsSocialLogin";
import { Modal, Button, Alert } from "react-bootstrap";

const Signup = (props) => {
  const history = useHistory();

  //We are using integer instead of bool in this version only for special purpose
  const [isSmallDevice, setIsSmallDevice] = useState(0);

  useEffect(() => {
    setIsSmallDevice(isMobileByViewPort() ? 1 : 0);
  }, []);

  const showSignupModal = props.displayedModal === "signup";
  const openLoginModal = () => {
    props.setDisplayedModal("login");
  };
  const openSignupModal = () => {
    props.setDisplayedModal("signup");
  };
  const closeAllModals = () => {
    props.setDisplayedModal(null);
  };

  const [step, setStep] = useState(1);

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(response.data);
    }
  };

  const countryFromDialingCode = (phoneCode) => {
    var country = "";
    countries.map((item) => {
      try {
        if (item.phonecode.toString() === phoneCode.toString()) {
          country = item.nicename;
          return false;
        }
      } catch (ex) {}
    });
    return country;
  };

  const [phoneCodesShown, setPhoneCodesShown] = useState(false);

  const [ipCountry, setIpCountry] = useState({});

  const getIpDetails = async () => {
    var response = await api.get("/ip-details");
    if (response.status === 200) {
      setIpCountry(response.data);
      updateForm("country", response.data.ip_country);
      updateForm("phoneCode", response.data.dialing_code);
    }
  };

  const [promoCode, setPromoCode] = useState("");
  const [validationChecked, setValidationChecked] = useState(false);

  useEffect(() => {
    setValidationChecked(false);
    getCountries();
    getIpDetails();
  }, []);

  const months = [
    __("Month"),
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July ",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);

  const emptyForm = {
    email: "",
    password: "",
    rePassword: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: ipCountry.ip_country,
    dateOfBirth: {
      year: (new Date().getFullYear() - 18).toString(),
      month: (new Date().getMonth() + 1).toString().padStart(2, "0"),
      day: new Date().getDate().toString().padStart(2, "0"),
    },
    gender: "Male",
    currency: "USD",
    language: "en",
    phoneCode: ipCountry.dialing_code,
    phone: "",
    agreeToTerms: false,
    receiveBonusOffer: false,
    bonusOffer: "no_bonus",
    promo: "",
  };

  const [form, setForm] = useState(emptyForm);

  const [errors, setErrors] = useState({});

  const [mainErrorMgs, setMainErrorMgs] = useState("");

  const updateForm = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
  };

  const updateCheckBox = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.checked;
    updateForm(field, value);
  };

  const updatePhoneCode = (phonecode) => {
    updateForm("phoneCode", "+" + phonecode);
    setPhoneCodesShown(false);
  };

  const updateDateOfBirth = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var dob = { ...form.dateOfBirth, [field]: value };
    updateForm("dateOfBirth", dob);
  };

  const nextStep = () => {
    if (step - isSmallDevice < 2) {
      setStep(step + 1);
    } else {
      doRegister();
    }
  };
  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const doRegister = async () => {
    props.setSpinner(true);
    var formClone = JSON.parse(JSON.stringify(form));
    formClone.dateOfBirth =
      form.dateOfBirth.year +
      "-" +
      form.dateOfBirth.month +
      "-" +
      form.dateOfBirth.day;
    var response = await api.post("/signup", formClone);
    setValidationChecked(true);
    props.setSpinner(false);
    if (response.status === 200) {
      setForm(emptyForm);
      closeAllModals();
      return history.push("/registration-complete/" + form.email);
    } else {
      if (response.data.errors) {
        var errors = response.data.errors;
        if (errors.bonusOffer) {
          setStep(1);
        } else if (errors.email || errors.password || errors.rePassword) {
          setStep(1 + isSmallDevice); //For mobile, its on step 2, for desktop, its on step 1
        }
        setErrors(errors);
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  return (
    <>
      <Modal
        show={showSignupModal}
        onHide={closeAllModals}
        className="steps_reg_popup"
        size="lg"
        id="newRegistrationModal"
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {" "}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="steps_reg_main_content">
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-danger" role="alert"></div>
              </div>
              <div className="col-md-12">
                <div className="step_counter_area">
                  <ul className="">
                    <li
                      className={
                        "step_count_field " + (step === 1 ? "current_step" : "")
                      }
                    >
                      <div className="count_number">1</div>
                      <div className="tick_mark">
                        <i className="fas fa-check"></i>
                      </div>
                    </li>
                    <li step-line="one" className="step_line_field">
                      <span></span>
                    </li>
                    <li
                      step="two"
                      className={
                        "step_count_field " + (step === 2 ? "current_step" : "")
                      }
                    >
                      <div className="count_number">2</div>
                      <div className="tick_mark">
                        <i className="fas fa-check"></i>
                      </div>
                    </li>
                    <li step-line="two" className="step_line_field moble_step">
                      <span></span>
                    </li>
                    <li
                      step="three"
                      className={
                        "step_count_field moble_step " +
                        (step === 3 ? "current_step" : "")
                      }
                    >
                      <div className="count_number">3</div>
                      <div className="tick_mark">
                        <i className="fas fa-check"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-12">
                <div className="reg_mian_heading">{__("Welcome")}</div>
                <div className="reg_common_headding">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="reg_main_text">
                        {__("Welcome to :brand_name! Have fun and win BIG!")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="reg_part_heading">
                        {__("Register by entering you details")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form id="registerFrom" method="post">
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    display: !isSmallDevice || step === 1 ? "block" : "none",
                  }}
                >
                  <div className="reg_bouns_container">
                    <div className="reg_bouns_area">
                      <RegCampaigns
                        country={form.country}
                        currency={form.currency}
                        onChange={(value) => updateForm("bonusOffer", value)}
                        onChangePromoCode={(value) =>
                          updateForm("promo", value)
                        }
                        promo={form.promo}
                        value={form.bonusOffer}
                      />
                    </div>
                    <input
                      className="next_info_step_button"
                      type="button"
                      value={__("Next Step")}
                      onClick={nextStep}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="reg_info_area">
                    {/*On mobile its step 2, on desktop its step 1*/}
                    <div
                      className={
                        "info_step " +
                        (step - isSmallDevice === 1 ? "visible" : "hidden")
                      }
                    >
                      <div className="step_heading">
                        {__("Contact Information")}
                      </div>

                      <a className="step_back_to_bonus_button" href="#">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                      </a>
                      <RegInput
                        validationChecked={validationChecked}
                        type="email"
                        placeholder={__("Email Address")}
                        errorText={errors.email}
                        infoText={__("Your email will be used to log in.")}
                        value={form.email}
                        name="email"
                        onChange={updateInput}
                      />

                      <RegInput
                        validationChecked={validationChecked}
                        type="password"
                        placeholder={__("Password")}
                        errorText={errors.password}
                        infoText={__(
                          "Your password must contain between 8 and 30 characters, including at least one number and one special character. Do not use your email address or username as your password."
                        )}
                        value={form.password}
                        name="password"
                        onChange={updateInput}
                      />

                      <RegInput
                        validationChecked={validationChecked}
                        type="password"
                        placeholder={__("Confirm Password")}
                        errorText={errors.rePassword}
                        infoText={__("Confirm your password please")}
                        value={form.rePassword}
                        name="rePassword"
                        onChange={updateInput}
                      />

                      <input
                        className="next_step_button"
                        onClick={nextStep}
                        type="button"
                        value={__("Next Step")}
                      />
                    </div>

                    <div
                      className={
                        "info_step " +
                        (step - isSmallDevice === 2 ? "visible" : "hidden")
                      }
                    >
                      <div className="step_heading">
                        {__("Contact Information")}
                      </div>
                      <a
                        className="step_back_button"
                        onClick={prevStep}
                        href="#"
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                      </a>
                      <RegInput
                        validationChecked={validationChecked}
                        type="text"
                        placeholder={__("First Name")}
                        errorText={errors.firstName}
                        value={form.firstName}
                        name="firstName"
                        onChange={updateInput}
                      />
                      <RegInput
                        validationChecked={validationChecked}
                        type="text"
                        placeholder={__("Last Name")}
                        errorText={errors.lastName}
                        value={form.lastName}
                        name="lastName"
                        onChange={updateInput}
                      />
                      <RegInput
                        validationChecked={validationChecked}
                        type="text"
                        placeholder={__("Address")}
                        errorText={errors.address}
                        value={form.address}
                        name="address"
                        onChange={updateInput}
                      />
                      <RegInput
                        validationChecked={validationChecked}
                        type="text"
                        placeholder={__("City")}
                        errorText={errors.city}
                        value={form.city}
                        name="city"
                        onChange={updateInput}
                      />
                      <RegInput
                        validationChecked={validationChecked}
                        type="text"
                        placeholder={__("Zip Code")}
                        errorText={errors.zip}
                        value={form.zip}
                        name="zip"
                        onChange={updateInput}
                      />

                      <div
                        className={
                          "single_input " +
                          (!validationChecked
                            ? ""
                            : !errors.country
                            ? "accept_input"
                            : "error_input")
                        }
                      >
                        <select
                          name="country"
                          className="reg_input"
                          value={form.country}
                          onChange={updateInput}
                        >
                          {countries.map((item, index) => {
                            if (item.code === "IR") {
                              return null;
                            } else {
                              return (
                                <option key={index} value={item.code}>
                                  {item.nicename}
                                </option>
                              );
                            }
                          })}
                        </select>
                        <div className="placeholder_text input_focused">
                          {__("Select Country")}
                        </div>
                        <div className="error_text">{errors.country}</div>
                        <i className="fas fa-check"></i>
                      </div>

                      <div className="single_input date_of_birth_input_area">
                        <select
                          required
                          name="day"
                          value={form.dateOfBirth.day}
                          onChange={updateDateOfBirth}
                          className="reg_input day_input"
                        >
                          <option value="">{__("Day")}</option>
                          {Array.from({ length: 31 }, (v, i) => i + 1).map(
                            (day) => (
                              <option
                                key={day}
                                value={day.toString().padStart(2, "0")}
                              >
                                {day.toString().padStart(2, "0")}
                              </option>
                            )
                          )}
                        </select>
                        <select
                          name="month"
                          required
                          value={form.dateOfBirth.month}
                          onChange={updateDateOfBirth}
                          className="reg_input month_input"
                        >
                          {Array.from({ length: 13 }, (v, i) => i).map(
                            (item) => (
                              <option
                                key={item}
                                value={item.toString().padStart(2, "0")}
                              >
                                {months[item]}
                              </option>
                            )
                          )}
                        </select>
                        <select
                          name="year"
                          required
                          value={form.dateOfBirth.year}
                          onChange={updateDateOfBirth}
                          className="reg_input year_input"
                        >
                          <option value="">{__("Year")}</option>
                          {Array.from(
                            { length: 70 },
                            (v, i) => new Date().getFullYear() - 18 - i
                          ).map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                        <div className="clearfix"></div>
                        <div className="placeholder_text input_focused">
                          {__("Date of birth")}
                        </div>
                        <div className="error_text">{__("Required Field")}</div>

                        <i className="fas fa-check"></i>
                      </div>
                      <div className="single_input">
                        <select
                          name="gender"
                          value={form.gender}
                          onChange={updateInput}
                          className="reg_input"
                        >
                          <option value="">{__("Gender")}</option>
                          <option value="Male">{__("Male")}</option>
                          <option value="Female">{__("Female")}</option>
                        </select>
                        <div className="placeholder_text input_focused">
                          {__("Select Gender")}
                        </div>
                        <div className="error_text">{__("Required Field")}</div>

                        <i className="fas fa-check"></i>
                      </div>
                      <div className="single_input accept_input">
                        <select
                          name="currency"
                          value={form.currency}
                          onChange={updateInput}
                          className="reg_input stepCurrency"
                        >
                          <option value="USD">XBU</option>
                        </select>
                        <div className="placeholder_text input_focused">
                          {__("Currency for game play")}
                        </div>
                        <div className="error_text">{__("Required Field")}</div>

                        <i className="fas fa-check"></i>
                      </div>

                      <div className="single_input accept_input">
                        <LanguageSelector
                          onChange={(value) => updateForm("language", value)}
                          value={form.language}
                          className="reg_input"
                        ></LanguageSelector>

                        <div className="placeholder_text input_focused">
                          {__("Select Language")}
                        </div>
                        <div className="error_text">{__("Required Field")}</div>

                        <i className="fas fa-check"></i>
                      </div>
                      <div className="phone_number_area">
                        <div className="single_input phone_code_area accept_input">
                          <div
                            onClick={() => setPhoneCodesShown(!phoneCodesShown)}
                          >
                            <label className="phone_code_label">
                              {form.phoneCode}{" "}
                              {countryFromDialingCode(form.phoneCode)}
                            </label>
                            <i className="fas fa-check"></i>
                            <img
                              alt="arrow-down.png"
                              className="select_arrow_down"
                              src={
                                require("assets/images/common/arrow-down.png")
                                  .default
                              }
                            />
                          </div>
                          <div
                            className={
                              "country_code_dropdown_area " +
                              (phoneCodesShown ? "visible" : "")
                            }
                          >
                            <ul>
                              {countries.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    updatePhoneCode(item.phonecode)
                                  }
                                >
                                  <div
                                    className={"flag bg-" + item.flag_classname}
                                  ></div>
                                  <span>
                                    {item.nicename} +{item.phonecode}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div className="placeholder_text input_focused">
                            {__("Select Country")}
                          </div>
                        </div>

                        <RegInput
                          validationChecked={validationChecked}
                          type="text"
                          placeholder={__("Mobile number")}
                          errorText={errors.phone}
                          value={form.phone}
                          name="phone"
                          onChange={updateInput}
                        />

                        <div className="clearfix"></div>
                      </div>

                      <div className="reg_check_area">
                        <input
                          type="checkbox"
                          className="stepBonusoffers"
                          value="1"
                          id="bonusoffers"
                          name="receiveBonusOffer"
                          onChange={updateCheckBox}
                        />
                        <label htmlFor="bonusoffers" className="check_area">
                          <div className="check_check_and_text_area">
                            <div className="check_box">
                              <div className="check_checked_box"></div>
                            </div>
                            <div className="check_text">
                              {__(
                                "Yes, I want to know about bonuses and free spins"
                              )}
                            </div>
                          </div>
                        </label>
                        <div className="error_text">{__("Required Field")}</div>
                      </div>

                      <div className="reg_check_area">
                        <input
                          type="checkbox"
                          className="stepTerms"
                          id="terms"
                          value="1"
                          name="agreeToTerms"
                          onChange={updateCheckBox}
                        />
                        <label htmlFor="terms" className="check_area">
                          <div className="check_check_and_text_area">
                            <div className="check_box">
                              <div className="check_checked_box"></div>
                            </div>
                            <div className="check_text">
                              {__("I accept the ")}{" "}
                              <Link target="_blank" to="/terms">
                                {__("Terms and Conditions")}
                              </Link>{" "}
                              {__("and")}{" "}
                              <Link target="_blank" to="/privacy">
                                {__("Privacy Policy")}
                              </Link>
                            </div>
                          </div>
                        </label>
                        <div className="error_text">{__("Required Field")}</div>
                      </div>

                      <input
                        className="next_step_button"
                        type="button"
                        value={__("Create Account")}
                        onClick={nextStep}
                      />
                    </div>

                    <div className="info_step hidden">
                      <input
                        type="hidden"
                        name="agent_promo"
                        value={promoCode}
                      />
                      <input
                        type="hidden"
                        name="g-recaptcha-response"
                        value=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signup;
