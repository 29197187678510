import ls from "services/ls";

//Auth module
import PrivateRoute from "routes/PrivateRoute";
import OpenRoute from "routes/OpenRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Translator from "services/Translator";

// Main Layout

import Games from "pages/Games";
import GamePlay from "pages/GamePlay";

import Terms from "pages/Terms";
import Privacy from "pages/Privacy";
import ResponsibleGaming from "pages/ResponsibleGaming";
import AboutUs from "pages/AboutUs";

import RegistrationComplete from "pages/RegistrationComplete";
import ActivateAccount from "pages/ActivateAccount";
import ResetPassword from "pages/ResetPassword";

import AccountActivated from "pages/AccountActivated";
import Cashier from "pages/players/Cashier";
import Withdraw from "pages/players/Withdraw";

//PrivateRoute Page
import Profile from "pages/players/Profile";
import TransactionHistory from "pages/players/TransactionHistory";
import GamingHistory from "pages/players/GamingHistory";
import BonusHistory from "pages/players/BonusHistory";
import BalanceTransfer from "pages/players/BalanceTransfer";
import BalanceTransferHistory from "pages/players/BalanceTransferHistory";
import FreeSpins from "pages/players/FreeSpins";
import Fortunes from "pages/players/Fortunes";
import Notifications from "pages/players/Notifications";
import Messages from "pages/players/Messages";
import RecommendUs from "pages/players/RecommendUs";
import AccountActivity from "pages/players/AccountActivity";
import FavoriteGames from "pages/players/FavoriteGames";
import Promotions from "pages/Promotions";
import AmlPolicy from "pages/AmlPolicy";
import BonusTerms from "pages/BonusTerms";
import NotFound404 from "pages/NotFound404";
import AgentSignup from "pages/AgentSignup";
import TicketDetails from "pages/TicketDetails";

import Blogs from "pages/Blogs";
import BlogDetails from "pages/BlogDetails";
import Vip from "pages/Vip";
import Support from "pages/Support";
import AccessControl from "pages/players/AccessControl";

var gameCategories = [
  "popular",
  "video_slots",
  "live_casino",
  "table_games",
  "jackpot",
  "new",
  "other",
  "bonus",
];

class OpenRoutes extends Component {
  constructor() {
    super();
    this.state = { language: Translator.getSelectedLanguage() };
  }

  componentDidMount() {
    ls.on("language", (newVal) => this.setState({ language: newVal }));
  }

  render() {
    return (
      <BrowserRouter basename={"/" + this.state.language.code + "/"}>
        <Switch>
          {/* Auth pages route */}
          <OpenRoute
            exact
            path="/account-activated"
            component={AccountActivated}
          ></OpenRoute>
          <OpenRoute exact path="/" component={Games}></OpenRoute>
          <OpenRoute
            exact
            path="/registration-complete/:email?"
            component={RegistrationComplete}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/activate-account/:email?"
            component={ActivateAccount}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/reset-password/:userId/:code"
            component={ResetPassword}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/promotions"
            component={Promotions}
          ></OpenRoute>
          <OpenRoute exact path="/vip-lounge" component={Vip}></OpenRoute>
          <OpenRoute exact path="/aml-policy" component={AmlPolicy}></OpenRoute>
          <OpenRoute
            exact
            path="/support/:type"
            component={Support}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/bonus-terms"
            component={BonusTerms}
          ></OpenRoute>
          {gameCategories.map((category, index) => (
            <OpenRoute
              key={index}
              exact
              path={"/" + category}
              category={category}
              component={Games}
            ></OpenRoute>
          ))}

          <OpenRoute
            exact
            name="play"
            path="/play/:slug/:mode?"
            component={GamePlay}
          ></OpenRoute>
          <OpenRoute exact path="/terms" component={Terms}></OpenRoute>
          <OpenRoute exact path="/privacy" component={Privacy}></OpenRoute>
          <OpenRoute exact path="/about-us" component={AboutUs}></OpenRoute>
          <OpenRoute
            exact
            path="/responsible-gaming"
            component={ResponsibleGaming}
          ></OpenRoute>
          <OpenRoute exact path="/blogs/:tag?" component={Blogs}></OpenRoute>
          <OpenRoute
            exact
            path="/blog/:slug"
            component={BlogDetails}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/agents/signup"
            component={AgentSignup}
          ></OpenRoute>
          <Redirect exact path="/cashier" to="/unauthorized"></Redirect>

          <OpenRoute exact path="*" component={NotFound404}></OpenRoute>
        </Switch>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  constructor() {
    super();
    this.state = { language: Translator.getSelectedLanguage() };
  }

  componentDidMount() {
    ls.on("language", (newVal) => this.setState({ language: newVal }));
  }

  render() {
    return (
      <BrowserRouter basename={"/" + this.state.language.code + "/"}>
        <Switch>
          {/*Employee routing*/}
          <PrivateRoute exact path="/" component={Games}></PrivateRoute>
          <PrivateRoute
            exact
            path="/profile"
            component={Profile}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/history/transactions"
            component={TransactionHistory}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/history/gaming"
            component={GamingHistory}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/history/bonus"
            component={BonusHistory}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/balance-transfer"
            component={BalanceTransfer}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/history/balance-transfer"
            component={BalanceTransferHistory}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/free-spins"
            component={FreeSpins}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/fortunes"
            component={Fortunes}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/notifications"
            component={Notifications}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/messages"
            component={Messages}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/recommend-us"
            component={RecommendUs}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/promotions"
            component={Promotions}
          ></PrivateRoute>
          <PrivateRoute exact path="/vip-lounge" component={Vip}></PrivateRoute>
          <PrivateRoute
            exact
            path="/aml-policy"
            component={AmlPolicy}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/support/:type"
            component={Support}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/blogs/:tag?"
            component={Blogs}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/blog/:slug"
            component={BlogDetails}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/cashier"
            component={Cashier}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/cashier/withdraw"
            component={Withdraw}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/access-control"
            component={AccessControl}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/account-activity"
            component={AccountActivity}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/favorite-games"
            component={FavoriteGames}
          ></PrivateRoute>
          {gameCategories.map((category, index) => (
            <PrivateRoute
              key={index}
              exact
              path={"/" + category}
              category={category}
              component={Games}
            ></PrivateRoute>
          ))}
          <PrivateRoute
            exact
            path="/play/:slug/:mode?"
            component={GamePlay}
          ></PrivateRoute>

          <PrivateRoute exact path="/terms" component={Terms}></PrivateRoute>
          <PrivateRoute
            exact
            path="/privacy"
            component={Privacy}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/about-us"
            component={AboutUs}
          ></PrivateRoute>
          <OpenRoute
            exact
            path="/bonus-terms"
            component={BonusTerms}
          ></OpenRoute>
          <PrivateRoute
            exact
            path="/responsible-gaming"
            component={ResponsibleGaming}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/agents/signup"
            component={AgentSignup}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/support/ticket/:id"
            component={TicketDetails}
          ></PrivateRoute>
          <PrivateRoute exact path="*" component={NotFound404}></PrivateRoute>

          {/*<Redirect path="*" to="/" />*/}
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
