import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";
import InputBox from "elements/InputBox";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function BalanceTransferHistory(props) {
  const defaultForm = {
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/history/balancetransfer", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);

    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const doAction = async (action, id) => {
    props.setSpinner(true);
    var response = await api.get(
      "/balance-transfer-action/" + action + "/" + id
    );
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert(null, response.data.message);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    if (action !== "resend-email") {
      getResults();
    }
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Balance Transfer History") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">
          {__("Balance Transfer History")}
        </div>

        <div className="my_account_filter_area">
          <div className="filter_form">
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <label>
                  <i className="fa fa-calendar"></i> {__("Start Date")}
                </label>
                <DatePicker
                  className="form-control"
                  placeholder="From"
                  value={form.startDate}
                  onChange={(itemValue) => updateForm(itemValue, "startDate")}
                />
              </div>
              <div className="col-xl-6 col-md-6">
                <label>
                  <i className="fa fa-calendar"></i> {__("End Date")}
                </label>
                <div className="row">
                  <div className="col-12">
                    <DatePicker
                      className="form-control"
                      placeholder="To"
                      value={form.endDate}
                      onChange={(itemValue) => updateForm(itemValue, "endDate")}
                    />
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="my_account_filter_action_area">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="common_brown_button"
                    onClick={getResults}
                  >
                    {__("Search")}
                  </button>{" "}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="my_account_table_listing">
          {results.length ? (
            <>
              <table id="box-table-a" className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">{__("Sender")}</th>
                    <th scope="col">{__("Receiver")}</th>
                    <th scope="col">{__("Amount")}</th>
                    <th scope="col">{__("Process Date")}</th>
                    <th scope="col">{__("Status")}</th>
                    <th scope="col">{__("Comment")}</th>
                    <th scope="col">{__("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((val, index) => (
                    <tr key={index}>
                      <td scope="col">
                        <div>
                          {__("Name")}:{val.sender}
                        </div>
                        <div>
                          {__("Email")}:{val.sender_email}
                        </div>
                      </td>
                      <td scope="col">
                        <div>
                          {__("Name")}:{val.receiver}
                        </div>
                        <div>
                          {__("Email")}:{val.receiver_email}
                        </div>
                      </td>
                      <td scope="col">{val.amount}</td>
                      <td scope="col">{val.process_date}</td>
                      <td scope="col">{val.status}</td>
                      <td scope="col">{val.comment}</td>
                      <td scope="col">
                        {val.status ===
                          "Waiting for confirmation from sender" &&
                          val.whoami === "sender" && (
                            <>
                              <a
                                className="common_brown_button re_action_on_balance_transfer"
                                onClick={() => doAction("resend-email", val.id)}
                                href="#"
                              >
                                {__("Resend Email")}
                              </a>
                              <a
                                className="common_brown_button re_action_on_balance_transfer"
                                onClick={() =>
                                  doAction("undo-transfer", val.id)
                                }
                                href="#"
                              >
                                {__("Undo Transfer")}
                              </a>
                            </>
                          )}
                        {val.status ===
                          "Waiting for confirmation from receiver" &&
                          val.whoami === "receiver" && (
                            <>
                              <a
                                className="common_brown_button re_action_on_balance_transfer"
                                onClick={() =>
                                  doAction("accept-transfer", val.id)
                                }
                                href="#"
                              >
                                {__("Accept")}
                              </a>
                              <a
                                className="common_brown_button re_action_on_balance_transfer"
                                onClick={() =>
                                  doAction("decline-transfer", val.id)
                                }
                                href="#"
                              >
                                {__("Decline")}
                              </a>
                            </>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </AccountPagesTemplate>
    </>
  );
}
