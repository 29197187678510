import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Link,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { __ } from "services/Translator";
import { Collapse } from "react-bootstrap";
import CategoryGames from "elements/CategoryGames";

import { Modal, Button, Alert } from "react-bootstrap";
export default function Landing(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const gameCategories = [
    "popular",
    "video_slots",
    "live_casino",
    "table_games",
    "jackpot",
    "new",
    "other",
    "bonus",
  ];

  useEffect(() => {
    var pathName = location.pathname.replace("/", "");
    if (gameCategories.indexOf(pathName) === -1 && pathName !== "") {
      props.showAlert(
        __("Unauthorized"),
        __("You need to be logged in to be able to access this page."),
        true,
        () => {
          history.push("/");
          // return <Redirect to="/" />;
        }
      );
    }
  }, []);

  const defaultGames = {
    popular: { gamelist: [] },
    video_slots: { gamelist: [] },
    live_casino: { gamelist: [] },
    table_games: { gamelist: [] },
    jackpot: { gamelist: [] },
    new: { gamelist: [] },
    bonus: { gamelist: [] },
    other: { gamelist: [] },
  };

  const gameTitles = {
    popular: __("Popular"),
    video_slots: __("Slots"),
    live_casino: __("Live Games"),
    table_games: __("Table Games"),
    jackpot: __("Jackpot Games"),
    new: __("New Games"),
    other: __("Others"),
    bonus: __("Bonus"),
    search_results: __("Search Results"),
  };

  const gameType = props.category ? props.category : null;

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [providers, setProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [games, setGames] = useState(defaultGames);

  const getGames = async (gameTypes, currentPage, perPage) => {
    setSearchStr("");
    setSearchSuggestions([]);
    console.log(gameTypes);
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: gameTypes,
      page: currentPage,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const getCategorizedGames = async (gameType, page) => {
    if (page === undefined) {
      page = 1;
    }
    if (gameType) {
      setCurrentPage(page);
      getGames(gameType, page, 105);
    } else {
      setCurrentPage(1);
      getGames(Object.keys(defaultGames), 1, 21);
    }
  };

  const onPageChange = (page) => {
    if (isSearchResultsShowing) {
      searchGames(page, null, null);
    } else {
      getCategorizedGames(gameType, page);
    }
  };

  const getAllGames = async () => {
    if (gameType) {
      getCategorizedGames(gameType, currentPage);
    } else {
      getGames(Object.keys(defaultGames), 1, 20);
    }
  };

  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };

  const getSearchSuggestions = async (text) => {
    setSearchStr(text);
    if (text.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: text,
        providers: selectedProviders.map(
          (providersIndex) => providers[parseInt(providersIndex)].provider_id
        ),
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  const selectProvider = (ev) => {
    var value = ev.target.value;
    var tmpProviders = [];
    if (value !== "") {
      tmpProviders.push(parseInt(value));
    }
    setSelectedProviders(tmpProviders);
    toggleProviders();
    searchGames(1, null, tmpProviders);
  };

  useEffect(() => {
    if (gameType) {
      props.setBreadcrumb([{ name: gameTitles[gameType] }]);
    } else {
      props.setBreadcrumb([]);
    }
    getProviders();
    getAllGames();
  }, [gameType]);

  const doSearchByGameName = (gameName) => {
    searchGames(1, gameName, null);
  };

  const searchGames = async (page, gameName, selectedProvidersTmp) => {
    var perPage = 12;
    setCurrentPage(page);
    if (gameName === null) {
      gameName = searchStr;
    } else {
      setSearchStr(gameName);
    }
    if (selectedProvidersTmp === null) {
      selectedProvidersTmp = selectedProviders;
    } else {
      setSelectedProviders(selectedProvidersTmp);
    }
    setSearchSuggestions([]);
    var finalProviders = selectedProvidersTmp.map(
      (providersIndex) => providers[parseInt(providersIndex)].provider_id
    );

    var serchParams = {
      providers: finalProviders,
      search_query: gameName,
      page: page,
      per_page: perPage,
    };
    console.log(serchParams);
    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const applyFilter = async () => {
    searchGames(1, null, selectedProviders);
    setShowFilter(false);
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  const [providersOpened, setProvidersOpened] = useState(false);
  const toggleProviders = () => {
    setProvidersOpened(!providersOpened);
  };

  return (
    <>
      <div className="content_section game_list_container container-fluid">
        <section className="game_menu_section row">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-md-12 game_menu_container">
                <div className="user-nav main_game_category_menu">
                  <a
                    href="#"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ padding: "8px 11px" }}
                    className="user-nav__button"
                  >
                    <span>
                      {gameType ? gameTitles[gameType] : __("All Games")}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="user-nav__arrow"
                    >
                      <path
                        fill="currentColor"
                        d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
                      />
                      <path fill="none" d="M0 0h24v24H0V0z" />
                    </svg>
                  </a>
                  <ul className="dropdown-menu main_game_category_dropdown">
                    <li className="popular_game_menu">
                      <Link to="/popular">
                        <span className="text">{__("Popular")}</span>
                      </Link>
                    </li>
                    <li className="slot_menu">
                      <Link to="/video_slots">
                        <span className="text">{__("Slots")}</span>
                      </Link>
                    </li>
                    <li className="live_casino_menu">
                      <Link style={{ marginTop: "-4px" }} to="/live_casino">
                        <span className="text">{__("Live Casino")}</span>
                      </Link>
                    </li>
                    <li className="table_game_menu">
                      <Link to="/table_games">
                        <span className="text">{__("Table Games")}</span>
                      </Link>
                    </li>
                    <li className="slot_menu">
                      <Link to="/jackpot">
                        <span className="text">{__("Jackpot")}</span>
                      </Link>
                    </li>
                    <li className="new_game_menu">
                      <Link to="/new">
                        <span className="text">{__("New Games")}</span>
                      </Link>
                    </li>
                    <li className="bonus_game_menu">
                      <Link to="/bonus">
                        <span className="text">{__("Bonus")}</span>
                      </Link>
                    </li>
                    <li className="other_game_menu">
                      <Link to="/other" href="#">
                        <span className="text">{__("Other")}</span>
                      </Link>
                    </li>

                    <li className="all_game_menu">
                      <Link to="/">
                        <span className="text">{__("All Games")}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="game_menu_content">
                  <ul className="game_menu">
                    <li
                      className={
                        "popular_game_menu " +
                        (gameType === "popular" ? "active" : "")
                      }
                    >
                      <Link to="/popular">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("Popular Games")}
                            src={
                              require("assets/images/common/game_icons/popular-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("Popular Games")}
                            src={
                              require("assets/images/common/game_icons/popular-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("Popular")}</span>
                      </Link>
                    </li>
                    <li
                      className={
                        "slot_menu " +
                        (gameType === "video_slots" ? "active" : "")
                      }
                    >
                      <Link to="/video_slots">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("Slot Games")}
                            src={
                              require("assets/images/common/game_icons/slots-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("Slot Games")}
                            src={
                              require("assets/images/common/game_icons/slots-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("Slots")}</span>
                      </Link>
                    </li>
                    <li
                      className={
                        "table_game_menu " +
                        (gameType === "table_games" ? "active" : "")
                      }
                    >
                      <Link to="/table_games">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("Table Games")}
                            src={
                              require("assets/images/common/game_icons/tablegames-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("Table Games")}
                            src={
                              require("assets/images/common/game_icons/tablegames-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("Table Games")}</span>
                      </Link>
                    </li>
                    <li
                      className={
                        "live_casino_menu " +
                        (gameType === "live_casino" ? "active" : "")
                      }
                    >
                      <Link to="/live_casino">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("Live Casino")}
                            src={
                              require("assets/images/common/game_icons/livecasino-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("Live Casino")}
                            src={
                              require("assets/images/common/game_icons/livecasino-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("Live Casino")}</span>
                      </Link>
                    </li>
                    <li
                      className={
                        "new_game_menu " +
                        (gameType === "jackpot" ? "active" : "")
                      }
                    >
                      <Link to="/jackpot">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("Jackpot")}
                            src={
                              require("assets/images/common/game_icons/slots-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("Jackpot")}
                            src={
                              require("assets/images/common/game_icons/slots-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("Jackpot")}</span>
                      </Link>
                    </li>

                    <li
                      className={
                        "bonus_game_menu " +
                        (gameType === "bonus" ? "active" : "")
                      }
                    >
                      <Link to="/bonus">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("Bonus Games")}
                            src={
                              require("assets/images/common/game_icons/star-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("Bonus Games")}
                            src={
                              require("assets/images/common/game_icons/star-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("Bonus")}</span>
                      </Link>
                    </li>

                    <li
                      className={
                        "new_game_menu " + (gameType === "new" ? "active" : "")
                      }
                    >
                      <Link to="/new">
                        <span className="icon">
                          <img
                            className="main_icon"
                            alt={__("New Games")}
                            src={
                              require("assets/images/common/game_icons/newgames-brown.svg")
                                .default
                            }
                          />
                          <img
                            className="hover_icon"
                            alt={__("New Games")}
                            src={
                              require("assets/images/common/game_icons/newgames-red.svg")
                                .default
                            }
                          />
                        </span>
                        <span className="text">{__("New Games")}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="game_search_container hide_game_search_item">
                  <div className="game_search_input_container">
                    <div className="game_search_area">
                      <input
                        className="game_search_input game_search_part"
                        value={searchStr}
                        onChange={(ev) => getSearchSuggestions(ev.target.value)}
                        placeholder={__("Game Search")}
                        type="text"
                      />
                      <a
                        className="game_search_action"
                        onClick={() => doSearchByGameName(searchStr)}
                      >
                        <i className="fas fa-search"></i>
                      </a>
                      <div className="clearfix"></div>
                    </div>
                    {!!searchSuggestions.length && (
                      <div
                        className="invisible_overlay"
                        onClick={() => setSearchSuggestions([])}
                      ></div>
                    )}
                    <Collapse
                      style={{ zIndex: 3050 }}
                      in={searchSuggestions.length}
                    >
                      <ul className="search_suggestions">
                        {searchSuggestions.map((item, index) => (
                          <li
                            onClick={() => doSearchByGameName(item)}
                            key={index}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </div>

                  <div className="game_search_by_provider_area">
                    <a
                      className="provider_button showProvider"
                      onClick={toggleProviders}
                    >
                      <span>
                        {selectedProviders.length
                          ? providers[selectedProviders[0]].name
                          : __("Select Vendor")}
                      </span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="user-nav__arrow"
                      >
                        <path
                          fill="currentColor"
                          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
                        />
                        <path fill="none" d="M0 0h24v24H0V0z" />
                      </svg>
                    </a>
                    {providersOpened && (
                      <div
                        className="invisible_overlay"
                        onClick={toggleProviders}
                      ></div>
                    )}
                    <Collapse style={{ zIndex: 3050 }} in={providersOpened}>
                      <ul className="provider_lists">
                        <li>
                          <input
                            id="all_provider"
                            className="providerList"
                            value=""
                            type="radio"
                            name="provider"
                            checked={selectedProviders.length === 0}
                            onClick={selectProvider}
                          />
                          <label className="check_box" htmlFor="all_provider">
                            <i className="fas fa-check"></i>
                          </label>
                          <label
                            htmlFor="all_provider"
                            className="provider_text"
                          >
                            {__("All Providers")}
                          </label>
                        </li>

                        {providers.map((item, index) => (
                          <li key={index}>
                            <input
                              id={"id_" + item.provider_id}
                              value={index}
                              className="providerList"
                              type="radio"
                              name="provider"
                              checked={selectedProviders.indexOf(index) !== -1}
                              onClick={selectProvider}
                            />
                            <label
                              className="check_box"
                              htmlFor={"id_" + item.provider_id}
                            >
                              <i className="fas fa-check"></i>
                            </label>
                            <label
                              htmlFor={"id_" + item.provider_id}
                              className="provider_text"
                            >
                              {item.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </div>

                  <select
                    className="game_sorting form-control d-none"
                    name="game_sorting"
                  >
                    <option value="">{__("Sorting")}</option>
                    <option value="Most Popular">{__("Most Popular")}</option>
                    <option value="Newest First">{__("Newest First")}</option>
                    <option value="A-Z Sorting">{__("A-Z Sorting")}</option>
                    <option value="Z-A Sorting">{__("Z-A Sorting")}</option>
                  </select>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>

        {Object.keys(games).map((type, index) => {
          return games[type].gamelist.length ? (
            <CategoryGames
              key={index}
              categoryName={gameTitles[type]}
              isSearchResults={isSearchResultsShowing}
              showMoreIcon={Object.keys(games).length > 1}
              category={type}
              gameList={games[type].gamelist}
              onPageChange={onPageChange}
              pagination={pagination}
              showPagination={Object.keys(games).length === 1}
            />
          ) : (
            <div key={index}></div>
          );
        })}
      </div>
    </>
  );
}
