import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import { isMobile, cauculateLandScape } from "services/isMobile";
import ls from "services/ls";
import { __ } from "services/Translator";
import { Modal, Button, Alert } from "react-bootstrap";

export default function GamePlay(props) {
  const params = useParams();
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [gamePlayMode, setGamePlayMode] = useState(params.mode);
  const [gameSessionId, setGameSessionId] = useState(null);

  const toggleFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("game_play_window");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const iframeSrcChanged = (event) => {
    // alert(event.target.contentWindow.location);
  };

  const getPlay = async () => {
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });
    props.setBreadcrumb([]);
    if (response.status === 200 && response.data) {
      setLaunchUrl(response.data.launchUrl);
      setGameSessionId(response.data.sessionId);
      setGameDetails(response.data.details);
      props.setBreadcrumb([{ name: response.data.details.game_name }]);
    } else {
      props.showAlert(
        "Warning!!",
        response.data.message,
        true,
        () => {
          history.push("/");
        },
        8000
      );
    }
  };

  const [newGames, setNewGames] = useState([]);
  const getNewGames = async () => {
    var response = await api.get("/new-games");
    if (response.status === 200) {
      setNewGames(response.data.list);
    }
  };

  const toggleFavoriteGame = async (gameId) => {
    var response = await api.post("/toggle-favorite-game", { gameId });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setGameDetails({ ...gameDetails, is_favorite_game: true });
      } else {
        setGameDetails({ ...gameDetails, is_favorite_game: false });
      }
    }
  };

  useEffect(() => {
    getPlay();
    getNewGames();
  }, [gamePlayMode, params.slug]);

  const [
    bonusUnsupportedWarningModal,
    setBonusUnsupportedWarningModal,
  ] = useState({ show: false });

  useEffect(() => {
    if (
      gameDetails &&
      props.userBalance &&
      gamePlayMode !== "demo" &&
      !gameDetails.bonus_supported &&
      parseFloat(props.userBalance.cash_balance) === 0.0
    ) {
      setBonusUnsupportedWarningModal({ show: true });
    } else {
      setBonusUnsupportedWarningModal({ show: false });
    }
  }, [gamePlayMode, props.userBalance.cash_balance, gameDetails]);

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: "Warning!!",
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
        setLaunchUrl(response.data.newSession.launchUrl);
      }
    }
  };

  useEffect(() => {
    var interval = setInterval(function () {
      if (gamePlayMode !== "demo") {
        loadGameHubMessage();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, gameDetails, isAuthenticated, gamePlayMode]);

  //GamePlay Page detection
  const isMobileDevice = props.isMobileDevice;
  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;
  const isLandscape = props.isLandscape;
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const calclateMobileIframeStyle = () => {
    var style = {
      width: "100vw",
      height: "calc(100vh - 52px)",
    };
    if (isLandscape) {
      style.height = "100vh";
    }
    setMobileIframeStyle(style);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();

    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  return (
    <>
      {!isMobileDevice && (
        <section className="game_play_container">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="game_main_area">
                  <iframe
                    id="game_play_window"
                    width="100%"
                    height="100%"
                    src={launchUrl}
                  ></iframe>
                  <div className="game_play_overlay"></div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="game-gray-bg game-sidebar">
                  <div className="game_player_control">
                    <ul>
                      <li className="close_game_li">
                        <Link className="close_game" to="/">
                          <i className="fas fa-times"></i>
                        </Link>
                      </li>
                      <li>
                        <a
                          className="fullscreen_game"
                          href="#"
                          onClick={toggleFullScreen}
                        >
                          <i className="fas fa-expand"></i>
                        </a>
                      </li>
                      <li>
                        <a className="zoomInScreen" rel="1" href="#">
                          <i className="fas fa-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a className="zoomOutScreen" rel="1" href="#">
                          <i className="fas fa-minus"></i>
                        </a>
                      </li>
                      {isAuthenticated && (
                        <li
                          className={
                            "favorite_game_li " +
                            (gameDetails.is_favorite_game
                              ? "added_as_favorite"
                              : "")
                          }
                        >
                          <a
                            className={
                              "favorite_game " +
                              (gameDetails.is_favorite_game
                                ? "remove_favorite_game"
                                : "add_favorite_game")
                            }
                            title={
                              gameDetails.is_favorite_game
                                ? __("Remove from favorite")
                                : __("Add to favorite")
                            }
                            onClick={() => toggleFavoriteGame(gameDetails.id)}
                            href="#"
                          >
                            <i className="fas fa-heart"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-sm-12 game-details-block">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>{__("Currently Playing")}</p>
                      </div>
                      <div className="col-sm-6">
                        <p>{gameDetails.game_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 game-details-block">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>{__("Provider")}</p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          {gameDetails.provider
                            ? gameDetails.provider.name
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 game-details-block">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>{__("Mode")}</p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          {gamePlayMode == "demo"
                            ? __("Fun Play")
                            : __("Real Play")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 game-details-block">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>{__("New Games")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 games-tab ">
                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="popular"
                      >
                        <ul>
                          {newGames.map((game, index) => (
                            <li key={index}>
                              <Link
                                to={
                                  "/play/" +
                                  game.slug +
                                  (gamePlayMode == "demo" ? "/demo" : "")
                                }
                              >
                                <img width="60" src={game.game_snapshot_url} />
                                <br />
                                <span>{game.game_name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {isAuthenticated && (
                    <div className="col-sm-12 game-details-btn">
                      <Link to="/cashier" className="common_brown_button">
                        {__("Cashier")}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>
      )}

      {isMobileDevice && (
        <>
          <iframe
            onLoad={iframeSrcChanged}
            src={launchUrl}
            style={mobileIframeStyle}
          />
        </>
      )}

      <Modal show={gameHubMessageModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type ==
                "maximum_bonus_wagers_per_round"
              ? __("Maximum bonus wagers limit exceeded!")
              : gameHubMessageModal.message_type == "bonus_balance_not_support"
              ? __("Insufficient balance!")
              : gameHubMessageModal.message_type == "maximum_wagers"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_loss"
              ? __("Maximum cash loss limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_playing_time"
              ? __("Maximum play time limit exceeded!")
              : gameHubMessageModal.title}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {gameHubMessageModal.message_type ==
            "maximum_cash_wagers_per_round" ? (
              <>
                You have exceeded the maximum bet amount for cash money. Please
                lower the amount and try again. See our{" "}
                <Link to="">General Terms & Conditions</Link> for more
                information.
              </>
            ) : gameHubMessageModal.message_type ==
              "maximum_bonus_wagers_per_round" ? (
              <>
                You have exceeded the maximum bet amount for bonus money. Please
                lower the amount and try again. See our{" "}
                <Link to="">Bonus Terms</Link> for more information.
              </>
            ) : gameHubMessageModal.message_type ==
              "bonus_balance_not_support" ? (
              <>
                Bonus play is not supported for this game. You do not have
                sufficient cash balance to play this game. See our{" "}
                <Link to="">Bonus Terms</Link> for more information. Note: For
                Live Casino games and Sports Betting, Only cash wagers are
                accepted (no bonus wagers)
              </>
            ) : (
              <>{gameHubMessageModal.message}</>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={bonusUnsupportedWarningModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Game playing with bonus money!")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__(
              "This game is not available for bonus money. Only cash money is accepted."
            )}
            <br />
            {__(
              "Note: All Live Casino games are not available for bonus money wagering."
            )}
            <br />
            <Link to="/bonus">{__("Click here")}</Link>{" "}
            {__("to see which games are available for Bonus Money playing.")}
          </div>
        </Modal.Body>
      </Modal>

      <br />
    </>
  );
}
