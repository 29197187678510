import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import Translator, { __ } from "services/Translator";
import NoRecordsFound from "elements/NoRecordsFound";

export default function NotFound404(props) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Page Not Found") }]);
    getLanguages();
  }, []);

  const [show404, setShow404] = useState(false);

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      var homeUrl = history.createHref({ pathname: "/" });
      var selectedLanguage = Translator.getSelectedLanguage();
      var currentLanguage = homeUrl.substr(1, 2);
      var prospectedLang = location.pathname.substr(1, 2).toLowerCase();
      if (prospectedLang.length === 2 && currentLanguage !== prospectedLang) {
        var foundLang = response.data.filter((item) => {
          if (item.code == prospectedLang) {
            return true;
          } else {
            return false;
          }
        });
        if (foundLang.length) {
          Translator.setSelectedLanguage(foundLang[0]);
          window.location.reload();
        } else {
          setShow404(true);
        }
      }
    }
  };

  return (
    <>
      {show404 ? (
        <div className="content_section game_list_container container-fluid">
          <NoRecordsFound
            content={__("The page you are looking for does not exist")}
          />
        </div>
      ) : (
        <div className="content_section game_list_container container-fluid">
          <NoRecordsFound content={__("Redirecting...")} />
        </div>
      )}
    </>
  );
}
