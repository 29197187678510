import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import { __ } from "services/Translator";

const RegInput = (props) => {
  const type = props.type ? props.type : "text";
  const isPassword = type === "password";
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const value = props.value;
  const placeholder = props.placeholder;
  const errorText = props.errorText;
  const infoText = props.infoText;
  const isValidInput = !errorText;
  const onChange = (ev) => {
    var value = ev.target.value;
    if (props.onChange) {
      props.onChange(ev);
    }
  };
  const togglePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => {
    if (!value.length) {
      setIsFocused(false);
    }
  };
  return (
    <div
      className={
        "single_input " +
        (!props.validationChecked
          ? ""
          : isValidInput
          ? "accept_input"
          : "error_input")
      }
    >
      <input
        name={props.name ? props.name : "input"}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        type={isPassword && isPasswordVisible ? "text" : type}
        value={value}
        autocomplete={isPassword ? "off" : "on"}
        className={"reg_input " + (isPassword ? "passwordInput" : "")}
      />
      {isPassword && (
        <div className="password_strength">
          <span className="strength_label">{__("Password Strength:")}</span>
          <span className="strength_result"></span>
        </div>
      )}
      <div className={"placeholder_text " + (isFocused ? "input_focused" : "")}>
        {placeholder}
      </div>
      <div className="error_text">{errorText}</div>
      <div className="input_info">{infoText}</div>
      <i className="fas fa-check"></i>
      {isPassword && (
        <a className="show_hide_password" onClick={togglePasswordVisible}>
          {isPasswordVisible ? (
            <i className="fas fa-eye-slash"></i>
          ) : (
            <i className="fas fa-eye"></i>
          )}
        </a>
      )}
    </div>
  );
};

export default RegInput;
