import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import ls from "services/ls";
import Pagination from "elements/Pagination";
import GameItem from "elements/GameItem";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function BonusHistory(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/games/favorites", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("My Favorite Games") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("My Favorite Games")}</div>

        {!!results.length ? (
          <>
            <div className="categorized_game_area ">
              <section className="single_categorized_game_area">
                <div className="game_lists_area favorite_games">
                  {results.map((game, index) => (
                    <GameItem key={game.id} game={game} key={index} />
                  ))}
                </div>
              </section>
            </div>

            <br />
            <br />
            <Pagination data={pagination} onPageChange={onPageChange} />
          </>
        ) : (
          <NoRecordsFound
            content={__("Looks like you did not add any favorite games.")}
          />
        )}
      </AccountPagesTemplate>
    </>
  );
}
