import React from "react";
import { __ } from "services/Translator";

export default function UserLevelMedals({ identity, labels }) {
  const identityInt = parseInt(identity);

  return (
    <>
      {identity === 0 || identity === 1 ? (
        <span className="user_medal beginner" title={__("Beginner")}>
          {labels ? __("Beginner") : ""}
        </span>
      ) : identity === 2 ? (
        <span className="user_medal bronze" title={__("Bronze")}>
          {labels ? __("Bronze") : ""}
        </span>
      ) : identity === 3 ? (
        <span className="user_medal silver" title={__("Silver")}>
          {labels ? __("Silver") : ""}
        </span>
      ) : identity === 4 ? (
        <span className="user_medal gold" title={__("Gold")}>
          {labels ? __("Gold") : ""}
        </span>
      ) : identity === 5 ? (
        <span className="user_medal platinum" title={__("Platinum")}>
          {labels ? __("Platinum") : ""}
        </span>
      ) : identity === 6 ? (
        <span className="user_medal diamond" title={__("Diamond")}>
          {labels ? __("Diamond") : ""}
        </span>
      ) : (
        <span></span>
      )}
    </>
  );
}
