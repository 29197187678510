import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";
import InputBox from "elements/InputBox";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function BonusHistory(props) {

  const defaultForm = {
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/history/bonus", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);

    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Bonus History") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Bonus History")}</div>

        <div className="my_account_filter_area">
          <div className="filter_form">
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <label>
                  <i className="fa fa-calendar"></i> {__("Start Date")}
                </label>
                <DatePicker
                  className="form-control"
                  placeholder="From"
                  value={form.startDate}
                  onChange={(itemValue) => updateForm(itemValue, "startDate")}
                />
              </div>
              <div className="col-xl-6 col-md-6">
                <label>
                  <i className="fa fa-calendar"></i> {__("End Date")}
                </label>
                <div className="row">
                  <div className="col-12">
                    <DatePicker
                      className="form-control"
                      placeholder="To"
                      value={form.endDate}
                      onChange={(itemValue) => updateForm(itemValue, "endDate")}
                    />
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="my_account_filter_action_area">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="common_brown_button"
                    onClick={getResults}
                  >
                    {__("Search")}
                  </button>{" "}
                  <button
                    type="button"
                    className="common_brown_button"
                    onClick={toggleExportModal}
                  >
                    {__("Export")}
                  </button>{" "}
                  <button
                    type="button"
                    className="common_brown_button"
                    onClick={doReset}
                  >
                    {__("Reset")}
                  </button>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="my_account_table_listing">
          {results.length ? (
            <>
              <table id="box-table-a" className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">{__("Date")}</th>
                    <th scope="col">{__("Tr ID")}</th>
                    <th scope="col">{__("Amount")}</th>
                    <th scope="col">{__("Status")}</th>
                    <th scope="col">{__("Comment")}</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((val, index) => (
                    <tr key={index}>
                      <td scope="col">{val.date_created}</td>
                      <td scope="col">{val.transaction_id}</td>
                      <td scope="col">{val.amount}</td>
                      <td scope="col">{val.status}</td>
                      <td scope="col">{val.comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>

        <Modal className="modal" show={exportModal} onHide={toggleExportModal}>
          <Modal.Header>
            <h5 className="modal-title">{__("Export Deposits History")}</h5>
            <button type="button" onClick={toggleExportModal} className="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <center>
              <Link
                className="common_brown_button"
                target="_blank"
                to="/export/history/transactions/xlsx"
              >
                {__("Excel")}
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link
                className="common_brown_button"
                target="_blank"
                to="/export/history/transactions/csv"
              >
                {__("CSV")}
              </Link>
              &nbsp;&nbsp;&nbsp;
            </center>
          </Modal.Body>
        </Modal>
      </AccountPagesTemplate>
    </>
  );
}
