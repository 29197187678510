import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { __ } from "services/Translator";

export default function RegistrationComplete(props) {
  const params = useParams();

  const history = useHistory();
  useEffect(() => {
    props.setBreadcrumb([{ name: __("Account Activated") }]);
    setTimeout(() => {
      props.setDisplayedModal('login');
      history.push("/");
    }, 5000);
  }, []);

  return (
    <>
      <section className="content_section container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="registration_complete_area">
                <h1 className="common_title">{__("Account Activated!")}</h1>
                <div className="clearfix"></div>
                <h4>{__("Thank you")}</h4>
                <p>
                  <b>
                    {__(
                      "Your account has been activated successfully. Please login using your email and password and enjoy."
                    )}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
