import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import DOMPurify from "dompurify";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function Notifications(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/player/notifications", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/notification/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Notifications") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Notifications")}</div>

        <div className="messages-page player_message_container">
          <div className="notifications-section">
            {results.length ? (
              <>
                <ul className="notifications-list">
                  {results.map((val, index) => (
                    <li className="notification-item" key={index}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(val.notification_str),
                        }}
                      ></p>
                      <div className="notification-time">
                        {val.notification_time}
                      </div>
                      <a
                        href="#"
                        className="delete_notification"
                        onClick={() => deleteItem(val.id)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </li>
                  ))}
                </ul>

                <div className="pagination">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
        </div>
      </AccountPagesTemplate>
    </>
  );
}
