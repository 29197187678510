import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function AccountActivity(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/history/account-activity", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Account Activity") }]);
  }, []);

  const doReset = () => {
    setForm(defaultForm);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Account Activity")}</div>

        <div className="my_account_table_listing">
          {results.length ? (
            <>
              <table id="box-table-a" className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">{__("Account Action")}</th>
                    <th scope="col">{__("IP Address")}</th>
                    <th scope="col">{__("Device")}</th>
                    <th scope="col">{__("Browser")}</th>
                    <th scope="col">{__("Importance")}</th>
                    <th scope="col">{__("Date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((val, index) => (
                    <tr key={index}>
                      <td scope="col">{val.account_action}</td>
                      <td scope="col">{val.ip_address}</td>
                      <td scope="col">{val.device}</td>

                      <td scope="col">{val.browser}</td>
                      <td scope="col">
                        {val.importance === 1 ? (
                          <span
                            className="fas fa-circle"
                            style={{ color: "red" }}
                          ></span>
                        ) : val.importance === 2 ? (
                          <span
                            className="fas fa-circle"
                            style={{ color: "yellow" }}
                          ></span>
                        ) : val.importance === 3 ? (
                          <span
                            className="fas fa-circle"
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td scope="col">{val.date_created}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </AccountPagesTemplate>
    </>
  );
}
