import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import DOMPurify from "dompurify";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function Messages(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/player/messages", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/message/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  const [fullMessage, setFullMessage] = useState(null);
  const showItem = async (message) => {
    setFullMessage(message);
    var response = await api.post("/player/message/mark-read", {
      id: message.id,
    });
    if (response.status !== 200) {
      props.showAlert(null, response.data.message, true);
    }
    getResults();
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Messages") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Messages")}</div>

        <div className="messages-page player_message_container">
          <div className="notifications-section">
            {results.length ? (
              <>
                <ul className="notifications-list">
                  {results.map((val, index) => (
                    <li key={index} className="message-item">
                      <div className="message-from">
                        <b>{__("From")}: </b>
                        {val.sender_name} {__("on")} {val.added_time}
                      </div>
                      <h4>
                        {__("Subject")}:{val.message_subject}
                        {!val.message_was_read && (
                          <>
                            <i className="fa fa-envelope unread_mark"></i>
                          </>
                        )}
                      </h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            val.message_body.substr(0, 150)
                          ),
                        }}
                      ></p>

                      <a
                        href="#"
                        className="open-modal button"
                        onClick={() => showItem(val)}
                      >
                        Read More
                      </a>

                      <a
                        href="#"
                        onClick={() => deleteItem(val.id)}
                        className="delete_msg"
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </li>
                  ))}
                </ul>

                <div className="pagination">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
        </div>

        {fullMessage && (
          <Modal
            className="modal player_message_modal"
            show={fullMessage}
            size="lg"
            onHide={() => setFullMessage(null)}
          >
            <Modal.Header>
              <h5 className="modal-title">{__("Message")}</h5>
              <button
                type="button"
                onClick={() => setFullMessage(null)}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="message-from">
                <b>{__("From")}: </b>
                {fullMessage.sender_name} {__("on")} {fullMessage.added_time}
              </div>
              <strong>
                {__("Subject")}:{fullMessage.message_subject}
              </strong>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(fullMessage.message_body),
                }}
              ></p>
            </Modal.Body>
          </Modal>
        )}
      </AccountPagesTemplate>
    </>
  );
}
