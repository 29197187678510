import logo from "./logo.svg";
import "./App.scss";
import React from "react";
import { OpenRoutes, PrivateRoutes } from "routes/router";
import auth from "services/auth";
import api from "services/api";
import Spinner from "elements/Spinner";
import AppContext from "contexts/AppContext";
import InactivityChecker from "elements/InactivityChecker";

function App(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [isIpBlocked, setIsIpBlocked] = React.useState(false);

  const appContext = React.useMemo(
    () => ({
      updateUserObj: async (data) => {
        // console.log("UserUpdated", data);
        await checkLoggedIn();
      },
    }),
    []
  );

  const checkLoggedIn = async () => {
    var authenticated = await auth.isAuthenticated();
    if (authenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };
  const checkIpBlocked = async () => {
    var response = await api.get("/is-ip-blocked");
    if (response.status === 200) {
      setIsIpBlocked(false);
    } else {
      setIsIpBlocked(response.data);
    }
  };

  const init = async () => {
    await checkIpBlocked();
    await checkLoggedIn();
    setIsReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  if (isIpBlocked) {
    return (
      <>
        <div>{isIpBlocked.message}</div>
      </>
    );
  } else if (isReady) {
    return (
      <AppContext.Provider value={appContext}>
        {!isLoggedIn && <OpenRoutes {...props} />}
        {isLoggedIn && (
          <React.Fragment>
            <InactivityChecker />
            <PrivateRoutes {...props} />
          </React.Fragment>
        )}
      </AppContext.Provider>
    );
  } else {
    return <Spinner />;
  }
}

export default App;
