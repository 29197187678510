import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";
import NoRecordsFound from "elements/NoRecordsFound";

export default function FreeSpins(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/list/free-spins", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Free Spins") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Free Spins")}</div>

        <div className="my_account_table_listing">
          {results.length ? (
            <>
              <table id="box-table-a" className="table table-striped">
                <thead>
                  <tr>
                    <th width="160" scope="col">
                      {__("Title")}
                    </th>
                    <th width="120" scope="col">
                      {__("Expiry Date")}
                    </th>
                    <th width="120" scope="col">
                      {__("Game")}
                    </th>
                    <th width="100" scope="col">
                      {__("Awarded Spins")}
                    </th>
                    <th width="100" scope="col">
                      {__("Remaining")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((val, index) => (
                    <tr key={index}>
                      <td scope="col">{val.title}</td>
                      <td scope="col">{val.expiry_date}</td>
                      <td scope="col">
                        {val.games.length ? (
                          <>
                            {val.games.map((game, i) => (
                              <div key={i}>
                                <Link to={"/play/" + game.slug}>
                                  {game.game_name}
                                </Link>
                              </div>
                            ))}
                          </>
                        ) : (
                          <strong>{__("Any supported game")}</strong>
                        )}
                      </td>
                      <td scope="col">{val.original_free_spins}</td>
                      <td scope="col">{val.free_spins}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </AccountPagesTemplate>
    </>
  );
}
