import React, { useState, useEffect, useRef } from "react";
import config from "configs/config";
import api from "services/api";
import { __ } from "services/Translator";

const LanguageSelector = (props) => {
  const className = props.className;

  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(response.data);
    }
  };

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <>
      <select name={props.name} className={className} onChange={onChange} value={props.value}>
        {languages.map((lang, index) => (
          <option key={index} value={lang.code}>{lang.language}</option>
        ))}
      </select>
    </>
  );
};

export default LanguageSelector;
