import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { Modal, Button, Alert, Collapse } from "react-bootstrap";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import { isMobile, cauculateLandScape } from "services/isMobile";
import { __ } from "services/Translator";

const MainMenu = (props) => {
  // const menuOpen = props.menuOpen;
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());
  const [isLandScapeDevice, setIsLandScapeDevice] = useState(
    cauculateLandScape()
  );

  const [contentPages, setcontentPages] = useState([]);
  const [isBlogActive, setIsBlogActive] = useState(true);

  return (
    <>
      <div className="container main_header_menu d-none d-lg-block">
        <nav className="main-nav">
          <Link to="/" className="main-nav__link">
            {__("Play Now")}
          </Link>
          <Link to="/promotions" className="main-nav__link">
            {__("Promotions")}
          </Link>
          <Link to="/cashier" className="main-nav__link">
            {__("Cashier")}
          </Link>
          <Link to="/vip-lounge" className="main-nav__link">
            {__("VIP Lounge")}
          </Link>
          <Link to="/agents/signup" className="main-nav__link">
            {__("Become an Agent")}
          </Link>

          {contentPages.map((item, index) => (
            <Link key={index} className="main-nav__link" to={"/page/" + item.page_uid}>
              {item.menu_title}
            </Link>
          ))}

          {isBlogActive && (
            <Link to="/blogs" className="main-nav__link">
              {__("Blog")}
            </Link>
          )}
          <Link to="/support/contact" className="main-nav__link">
            {__("Contact")}
          </Link>
        </nav>
        <div className="clearfix"></div>
      </div>
    </>
  );
};

export default MainMenu;
