import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { Modal, Button, Alert, Collapse } from "react-bootstrap";
import auth from "services/auth";
import { isMobile, cauculateLandScape } from "services/isMobile";
import { __ } from "services/Translator";

const Footer = (props) => {
  // const menuOpen = props.menuOpen;
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());
  const [isLandScapeDevice, setIsLandScapeDevice] = useState(
    cauculateLandScape()
  );

  const [contentPages, setcontentPages] = useState([]);
  const [siteVariables, setSiteVariables] = useState({});
  const [isBlogActive, setIsBlogActive] = useState(true);
  const isAuthenticated = props.isAuthenticated;
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="footer_banner">
        <a
          title="XBIT Coin Is Representing A Revenue Share Of Casino XBIT"
          target="_blank"
          href="https://www.xbitcc.com"
        >
          <img
            alt="XBIT Coin Is Representing A Revenue Share Of Casino XBIT"
            src={
              require("assets/images/footer/casino-xbit-main-banner.jpg")
                .default
            }
          />
        </a>
      </div>

      <footer className="page__footer desktop_footer_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="legal-notice">
                <a href="#">
                  <iframe
                    src="https://licensing.gaming-curacao.com/validator/?lh=e34dce73ded4cd9ddd6f8eb246616083&template=tseal"
                    width="150"
                    height="50"
                    style={{ border: "none", verticalAlign: "bottom" }}
                  ></iframe>
                </a>
                <a href="#">
                  <img
                    src={require("assets/img/partner-logos/18plus.png").default}
                    style={{ marginLeft: "10px" }}
                    width="45"
                    alt="18plus.png"
                  />
                </a>
                <a target="_blank" href="https://www.gamcare.org.uk">
                  <img
                    src={
                      require("assets/img/partner-logos/gamcare-logo.png")
                        .default
                    }
                    style={{ marginLeft: "10px" }}
                    width="140"
                    alt="gamcare-logo.png"
                  />
                </a>
                <a target="_blank" href="https://www.gamstop.co.uk">
                  <img
                    src={
                      require("assets/img/partner-logos/gam-stop-logo.svg")
                        .default
                    }
                    style={{ marginLeft: "10px" }}
                    width="140"
                    alt="gam-stop-logo.svg"
                  />
                </a>
                <p>
                  {__("footer text first part")}{" "}
                  <a target="_blank" href={siteVariables.license_url}>
                    {siteVariables.company_license}
                  </a>{" "}
                  {__("footer text last part")}
                </p>
              </div>
              <div className="social_links">
                <ul>
                  <li>
                    <a target="_blank" href="https://twitter.com/xbitplatform">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/xbit-gaming/"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://bitcointalk.org/index.php?action=profile;u=3321042;sa=summary"
                    >
                      <i className="fab fa-btc"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UC7pVXGdWlzbdJ6tlOpipFRA"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/xbit_gamingplatform"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://t.me/XBITCasino">
                      <i className="fab fa-telegram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="partner-logos">
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://www.counos.io/">
                    <img
                      src={
                        require("assets/img/partner-logos/payment-1.png")
                          .default
                      }
                      alt="Counos"
                    />
                  </a>
                </div>
                {/* <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://exchange.koopal.com/">
                    <img
                      src={
                        require("assets/img/partner-logos/koopal-ex.png")
                          .default
                      }
                      alt="Koopal EX"
                    />
                  </a>
                </div> */}
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://btc6x.com">
                    <img
                      src={
                        require("assets/img/partner-logos/btc6x.png").default
                      }
                      alt="btc6x"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://www.real001.com">
                    <img
                      src={require("assets/img/partner-logos/real.png").default}
                      alt="real"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://xbitplay.com">
                    <img
                      src={
                        require("assets/img/partner-logos/xbit-play.png")
                          .default
                      }
                      alt="xbit-play"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://lotto.xbitcc.com">
                    <img
                      src={
                        require("assets/img/partner-logos/xbit-lotto.png")
                          .default
                      }
                      alt="xbit-lotto"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://escrow.counos.io">
                    <img
                      src={
                        require("assets/img/partner-logos/payment-3.png")
                          .default
                      }
                      alt="Counos Escrow"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://walletgenerator.counos.io">
                    <img
                      src={
                        require("assets/img/partner-logos/payment-4.png")
                          .default
                      }
                      alt="Paper Wallet"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://xbitcc.com/xbit/buy/">
                    <img
                      src={
                        require("assets/img/partner-logos/payment-5.png")
                          .default
                      }
                      alt="Direct Buy"
                    />
                  </a>
                </div>
                <div className="partner-logos__item footer_payment_logo">
                  <a target="_blank" href="https://app.counos.io/wallet/">
                    <img
                      src={
                        require("assets/img/partner-logos/payment-6.png")
                          .default
                      }
                      alt="Web Wallet"
                    />
                  </a>
                </div>
              </div>
              <ul className="footer_payemnt_logo">
                <li>
                  <a target="_blank" href="https://xbitcc.com">
                    <img
                      src={require("assets/img/partner-logos/xbit.png").default}
                      alt="xbit"
                    />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.counos.io/counos-coin">
                    <img
                      src={require("assets/img/partner-logos/cca.png").default}
                      alt="Counos Coin"
                    />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.counos.io/CounosX">
                    <img
                      src={require("assets/img/partner-logos/ccxx.png").default}
                      alt="Counos X"
                    />
                  </a>
                </li>
                <li>
                  <img
                    src={require("assets/img/partner-logos/wxbit.png").default}
                    alt="W xbit"
                  />
                </li>
                <li>
                  <img
                    src={require("assets/img/partner-logos/dash.png").default}
                    alt="Desh"
                  />
                </li>

                <li>
                  <img
                    src={require("assets/img/partner-logos/btc.png").default}
                    alt="Bitcoin"
                  />
                </li>
                <li>
                  <img
                    src={require("assets/img/partner-logos/eth.png").default}
                    alt="Ethereum"
                  />
                </li>
                <li>
                  <img
                    src={require("assets/img/partner-logos/ltc.png").default}
                    alt="Litecoin"
                  />
                </li>

                <li>
                  <img
                    src={require("assets/img/partner-logos/usdt.png").default}
                    alt="Tether"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr />

        <div className="container">
          <div className="row">
            <div className="col-xl-9 order-xl-2">
              <nav className="footer-nav">
                <div className="footer-nav__item">
                  <Link to="/about-us">{__("About us")}</Link>
                </div>

                <div className="footer-nav__item">
                  <Link to="/terms">{__("Terms & conditions")}</Link>
                </div>
                <div className="footer-nav__item">
                  <Link to="/aml-policy">{__("AML Policy")}</Link>
                </div>
                <div className="footer-nav__item">
                  <Link to="/bonus-terms">{__("Bonus terms")}</Link>
                </div>
                <div className="footer-nav__item">
                  <Link to="/privacy">{__("Privacy Policy")}</Link>
                </div>
                <div className="footer-nav__item">
                  <Link to="/responsible-gaming">
                    {__("Responsible Gaming")}
                  </Link>
                </div>

                {isAuthenticated && (
                  <div className="footer-nav__item">
                    <Link to="/history/transactions">
                      {__("Transaction History")}
                    </Link>
                  </div>
                )}

                <div className="footer-nav__item">
                  <Link to="/agents/signup">{__("Agent Sign Up")}</Link>
                </div>

                {contentPages.map((item, index) => (
                  <div className="footer-nav__item" key={index}>
                    <Link
                      className="main-nav__link"
                      to={"/page/" + item.page_uid}
                    >
                      {item.menu_title}
                    </Link>
                  </div>
                ))}
              </nav>
            </div>

            <div className="col-xl-3 order-xl-1">
              <div className="footer_copyright_area">
                <Link to="/" className="footer_logo">
                  Gaming Platform <span className="outline">Xbit</span>
                  <img
                    src={require("assets/images/common/logo-xbit.png").default}
                    alt={siteVariables.brand_name}
                  />
                </Link>
                <div className="copyrights">
                  © {currentYear} {__("All rights reserved")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className="mobile_footer_area page__footer">
        <div className="container">
          <div className="footer_logo_area">
            <Link to="/" className="footer_logo" className="footer_logo">
              <img
                src={require("assets/images/common/logo-xbit.png").default}
                width="140"
                alt={siteVariables.brand_name}
              />
            </Link>
          </div>
          <div className="footer_menu">
            <ul className="">
              <li>
                <Link to="/about">
                  <i className="fas fa-arrow-right"></i>
                  {__("About Us")}
                </Link>
              </li>

              <li>
                <Link to="/terms">
                  <i className="fas fa-arrow-right"></i>
                  {__("Terms & conditions")}
                </Link>
              </li>
              <li>
                <Link to="/aml-policy">
                  <i className="fas fa-arrow-right"></i>
                  {__("AML Policy")}
                </Link>
              </li>
              <li>
                <Link to="/bonus-terms">
                  <i className="fas fa-arrow-right"></i>
                  {__("Bonus terms")}
                </Link>
              </li>
              <li>
                <Link to="/privacy">
                  <i className="fas fa-arrow-right"></i>
                  {__("Privacy Policy")}
                </Link>
              </li>
              <li>
                <Link to="/responsible-gaming">
                  <i className="fas fa-arrow-right"></i>
                  {__("Responsible Gaming")}
                </Link>
              </li>
              {isAuthenticated && (
                <li>
                  <Link to="/history/transactions">
                    <i className="fas fa-arrow-right"></i>
                    {__("Transaction History")}
                  </Link>
                </li>
              )}

              <li>
                <Link to="/agents/signup">
                  <i className="fas fa-arrow-right"></i>
                  {__("Agent Sign Up")}
                </Link>
              </li>

              {contentPages.map((item, index) => (
                <li key={index}>
                  <Link
                    className="main-nav__link"
                    to={"/page/" + item.page_uid}
                  >
                    <i className="fas fa-arrow-right"></i> {item.menu_title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="partner-logos">
            <div className="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://www.counos.io/">
                <img
                  src={
                    require("assets/img/partner-logos/payment-1.png").default
                  }
                  alt="Counos"
                />
              </a>
            </div>
            {/* <div className="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://exchange.koopal.com/">
                <img
                  src={
                    require("assets/img/partner-logos/koopal-ex.png").default
                  }
                  alt="Koopal EX"
                />
              </a>
            </div> */}

            <div class="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://btc6x.com">
                <img
                  src={require("assets/img/partner-logos/btc6x.png").default}
                  alt="btc6x"
                />
              </a>
            </div>
            <div class="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://www.real001.com">
                <img
                  src={require("assets/img/partner-logos/real.png").default}
                  alt="real"
                />
              </a>
            </div>
            <div class="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://xbitplay.com">
                <img
                  src={
                    require("assets/img/partner-logos/xbit-play.png").default
                  }
                  alt="xbit-play"
                />
              </a>
            </div>
            <div class="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://lotto.xbitcc.com">
                <img
                  src={
                    require("assets/img/partner-logos/xbit-lotto.png").default
                  }
                  alt="xbit-lotto"
                />
              </a>
            </div>

            <div className="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://escrow.counos.io">
                <img
                  src={
                    require("assets/img/partner-logos/payment-3.png").default
                  }
                  alt="Counos Escrow"
                />
              </a>
            </div>
            <div className="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://walletgenerator.counos.io">
                <img
                  src={
                    require("assets/img/partner-logos/payment-4.png").default
                  }
                  alt="Paper Wallet"
                />
              </a>
            </div>
            <div className="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://xbitcc.com/xbit/buy/">
                <img
                  src={
                    require("assets/img/partner-logos/payment-5.png").default
                  }
                  alt="Direct Buy"
                />
              </a>
            </div>
            <div className="partner-logos__item footer_payment_logo">
              <a target="_blank" href="https://app.counos.io/wallet/">
                <img
                  src={
                    require("assets/img/partner-logos/payment-6.png").default
                  }
                  alt="Web Wallet"
                />
              </a>
            </div>
          </div>

          <ul className="footer_payemnt_logo">
            <li>
              <a target="_blank" href="https://xbitcc.com">
                <img
                  src={require("assets/img/partner-logos/xbit.png").default}
                  alt="xbit"
                />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.counos.io/counos-coin">
                <img
                  src={require("assets/img/partner-logos/cca.png").default}
                  alt="Counos Coin"
                />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.counos.io/CounosX">
                <img
                  src={require("assets/img/partner-logos/ccxx.png").default}
                  alt="Counos X"
                />
              </a>
            </li>
            <li>
              <img
                src={require("assets/img/partner-logos/wxbit.png").default}
                alt="W xbit"
              />
            </li>
            <li>
              <img
                src={require("assets/img/partner-logos/dash.png").default}
                alt="Desh"
              />
            </li>

            <li>
              <img
                src={require("assets/img/partner-logos/btc.png").default}
                alt="Bitcoin"
              />
            </li>
            <li>
              <img
                src={require("assets/img/partner-logos/eth.png").default}
                alt="Ethereum"
              />
            </li>
            <li>
              <img
                src={require("assets/img/partner-logos/ltc.png").default}
                alt="Litecoin"
              />
            </li>

            <li>
              <img
                src={require("assets/img/partner-logos/usdt.png").default}
                alt="Tether"
              />
            </li>
          </ul>

          <div className="legal-notice">
            <a href="#">
              <iframe
                src="https://licensing.gaming-curacao.com/validator/?lh=e34dce73ded4cd9ddd6f8eb246616083&template=tseal"
                width="150"
                height="50"
                style={{ border: "none", verticalAlign: "bottom" }}
              ></iframe>
            </a>
            <a href="#">
              <img
                src={require("assets/img/partner-logos/18plus.png").default}
                style={{ marginLeft: "10px" }}
                width="45"
                alt="18plus.png"
              />
            </a>
            <a target="_blank" href="https://www.gamcare.org.uk">
              <img
                src={
                  require("assets/img/partner-logos/gamcare-logo.png").default
                }
                width="140"
                alt="gamcare-logo.png"
              />
            </a>
            <a target="_blank" href="https://www.gamstop.co.uk">
              <img
                src={
                  require("assets/img/partner-logos/gam-stop-logo.svg").default
                }
                width="140"
                alt="gam-stop-logo.svg"
              />
            </a>
          </div>
          <p className="footer_text">
            {__("footer text first part")}{" "}
            <a target="_blank" href={siteVariables.license_url}>
              {siteVariables.company_license}
            </a>{" "}
            {__("footer text last part")}
          </p>

          <div className="social_links">
            <ul>
              <li>
                <a target="_blank" href="https://twitter.com/xbitplatform">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/xbit-gaming/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://bitcointalk.org/index.php?action=profile;u=3321042;sa=summary"
                >
                  <i className="fab fa-btc"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC7pVXGdWlzbdJ6tlOpipFRA"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/xbit_gamingplatform"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/XBITCasino">
                  <i className="fab fa-telegram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="footer_copyright_area">
            s
            <Link to="/" className="footer_logo">
              Gaming Platform <span className="outline">Xbit</span>
              <img
                style={{ width: "25px" }}
                src={require("assets/images/common/logo-xbit.png").default}
                alt={siteVariables.brand_name}
              />
            </Link>
            <div className="copyrights">
              © {currentYear} {__("All rights reserved")}
            </div>
          </div>
        </div>

        <hr />
      </footer>
    </>
  );
};

export default Footer;
