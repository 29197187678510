import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";

import NoRecordsFound from "elements/NoRecordsFound";

export default function Blogs(props) {
  const params = useParams();
  const slug = params.slug;

  const [blog, setBlog] = useState({});

  const getBlog = async () => {
    props.setSpinner(true);
    var response = await api.get("/article/" + slug);
    props.setSpinner(false);
    props.setBreadcrumb([]);
    if (response.status === 200) {
      setBlog(response.data.data);
      props.setBreadcrumb([
        { name: __("Blogs"), link: "/blogs" },
        { name: response.data.data.details.title },
      ]);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getBlog();
  }, [slug]);

  return (
    <>
      <section className="content_section container">
        <div className="banking_area blog_details_container">
          {!!blog.id && (
            <div className="banking_heading_area">
              <div className="tag_and_date_area">
                <div className="tag_items">
                  {!!blog.tag_lists.length && (
                    <>
                      {blog.tag_lists.map((tag, index) => (
                        <Link key={index} to={"/blogs/" + tag}>
                          {tag}{" "}
                        </Link>
                      ))}
                    </>
                  )}
                </div>
                <div className="date_area">{blog.date}</div>
                <div className="clearfix"></div>
              </div>
              <h1 className="common_title">{blog.details.title}</h1>

              <div className="blog_banner">
                <img
                  width="100%"
                  alt={blog.details.title}
                  src={blog.blog_image_path}
                />
              </div>

              <div
                className="blog_content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blog.details.content),
                }}
              ></div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
