import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import { __ } from "services/Translator";

const GameItem = ({ game }) => {
  const [visible, setVisible] = useState(true);
  const defaultImage = require("assets/images/casinoxbit-loader.gif").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(defaultImage);

  const hideNoImageFoundGames = () => {
    setVisible(false);
  };
  const loadGameImage = () => {
    setGameSnapshotUrl(game.game_snapshot_url);
  };

  useEffect(() => {
    setVisible(true);
    setGameSnapshotUrl(defaultImage);
  }, [game.id]);

  return visible ? (
    <div className={"single_game " + (game.has_jackpot ? "jackpot_game" : "")}>
      <img
        alt=""
        onError={hideNoImageFoundGames}
        onLoad={loadGameImage}
        loading="lazy"
        className="game_image loading"
        src={gameSnapshotUrl}
      />
      <div className="game_overlay">
        <h1 className="game_name">{game.game_name}</h1>
        <h3 className="game_provider_name">{game.category}</h3>
        <div className="game_action">
          <Link className="game_play_action" to={"/play/" + game.slug}>
            {__("Play")}
          </Link>
          {!!game.play_for_fun_supported && (
            <Link
              className="demo_game_play_action"
              to={"/play/" + game.slug + "/demo"}
            >
              {__("Demo")}
            </Link>
          )}
        </div>
      </div>
      {!!game.jackpot_amount && (
        <div className="jackpot_amount">{game.jackpot_amount}</div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default GameItem;
