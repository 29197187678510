import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import { __ } from "services/Translator";

import NoRecordsFound from "elements/NoRecordsFound";

export default function Blogs(props) {
  var params = useParams();
  const defaultForm = {
    tag: params.tag ? params.tag : null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    var response = await api.post("/articles", form);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Blogs") }]);
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch, form.tag]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <section className="content_section container">
        <div className="banking_area blog_lists_container">
          <div className="banking_heading_area">
            <h1 className="common_title">{__("Blogs")}</h1>
          </div>
          {results.length ? (
            <>
              <div className="blog_lists_area">
                {results.map((val, index) => (
                  <div className="single_blog" key={index}>
                    <div className="blog_image">
                      <Link to={"/blog/" + val.slug}>
                        <img
                          alt={val.blog_content.title}
                          src={val.blog_image_path}
                        />
                      </Link>
                    </div>

                    <div className="tag_and_date_area">
                      <div className="tag_items">
                        {!!val.tag_lists.length && (
                          <>
                            {val.tag_lists.map((val2, index2) => (
                              <Link key={index2} to={"/blogs/" + val2}>
                                {val2}{" "}
                              </Link>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="date_area">{val.date}</div>
                      <div className="clearfix"></div>
                    </div>

                    <h6 title={val.blog_content.title} className="blog_title">
                      <Link to={"/blog/" + val.slug}>
                        {val.blog_content.title}
                      </Link>
                    </h6>
                  </div>
                ))}
              </div>

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </section>
    </>
  );
}
