import { useEffect } from "react";
import { __ } from "services/Translator";

export default function VipLevelText(props) {
  var identityText = "";
  switch (props.identity) {
    case 0:
    case 1:
      identityText = __("Beginner");
      break;
    case 2:
      identityText = __("Bronze");
      break;
    case 3:
      identityText = __("Silver");
      break;
    case 4:
      identityText = __("Gold");
      break;
    case 5:
      identityText = __("Platinum");
      break;
    case 6:
      identityText = __("Diamond");
      break;
    default:
      identityText = __("Calculating");
  }
  return identityText;
}

export function NextVipLevelText(props) {
  var identityText = "";
  switch (props.identity) {
    case 0:
    case 1:
      identityText = __("Bronze");
      break;
    case 2:
      identityText = __("Silver");
      break;
    case 3:
      identityText = __("Gold");
      break;
    case 4:
      identityText = __("Platinum");
      break;
    case 5:
      identityText = __("Diamond");
      break;
  }
  return identityText;
}
