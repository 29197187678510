import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import AppContext from "contexts/AppContext";
import ls from "services/ls";
import { __ } from "services/Translator";

export default function Promotions(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");
    props.setBreadcrumb([]);
    if (response.status === 200) {
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
      props.setBreadcrumb([{ name: response.data.content_data.page_title }]);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const toggleExpanded = (index) => {
    var promotionsClone = JSON.parse(JSON.stringify(promotions));
    promotionsClone[index].expanded = !promotionsClone[index].expanded;
    setPromotions(promotionsClone);
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <section className="content_section container">
        <div className="promotion_area">
          <div className="common_heading_area">
            <h1 className="common_title pull-left">
              {pageContent.page_title
                ? pageContent.page_title
                : ""}
            </h1>

            <ul className="common_tab_menu pull-right">
              <li>
                <Link className="active" to="/promotions">
                  {__("Welcome Bonuses")}
                </Link>
              </li>
              <li>
                <Link to="/bonus-schedule">{__("Current Bonuses")}</Link>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>

          <div className="promotions">
            <h5>{__("Welcome Bonuses")}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(pageContent.content),
              }}
            ></p>
            <div className="row">
              {promotions.map((promotion, index) => {
                var details = DOMPurify.sanitize(promotion.details);
                var shortText = DOMPurify.sanitize(
                  promotion.details.substr(0, 210)
                );
                var showMore = true;
                if (details === shortText) {
                  showMore = false;
                }

                return (
                  <div className="col-md-6 single-promotion" key={index}>
                    <img src={promotion.image} height="230" alt="t1.jpg" />
                    <h5>{promotion.title}</h5>

                    <div className="promotion_main_text_area">
                      <div
                        className={
                          "promotion_main_text " +
                          (promotion.expanded ? "open" : "")
                        }
                      >
                        {showMore ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: promotion.expanded
                                  ? details
                                  : shortText,
                              }}
                            ></span>
                          </>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: details,
                            }}
                          ></span>
                        )}
                      </div>
                      <a
                        style={{ position: "absolute", right: 0, bottom: -12 }}
                        onClick={() => toggleExpanded(index)}
                      >
                        {promotion.expanded ? __("Less") : __("Read More")}
                      </a>
                    </div>
                    <p className="promotion_action_area">
                      {isAuthenticated ? (
                        <Link className="common_brown_button" to="/cashier">
                          {__("Claim Bonus")}
                        </Link>
                      ) : (
                        <a
                          className="common_brown_button"
                          href="#"
                          onClick={() => props.setDisplayedModal("signup")}
                        >
                          {__("Claim Bonus")}
                        </a>
                      )}{" "}
                      <Link
                        className="common_brown_button"
                        target="_blank"
                        to="/bonus-terms"
                      >
                        {__("Explore Terms")}
                      </Link>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
