import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import auth from "services/auth";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { __ } from "services/Translator";

const ProfileSideMenu = (props) => {
  const location = useLocation();
  const [notificationCount, setNotificationsCount] = useState(0);
  const [freeSpinsCount, setFreeSpinsCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);
  const visibleOnMobile = props.visibleOnMobile;
  return (
    <>
      <ul className={"account_menu " + (visibleOnMobile ? "open" : "")}>
        <li>
          <Link
            to="/profile"
            className={location.pathname === "/profile" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-user"></i>
            </span>
            <span className="left_menu_text">{__("Account Details")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/cashier"
            className={location.pathname === "/cashier" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-money-bill-wave"></i>
            </span>
            <span className="left_menu_text">{__("Cashier")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/history/transactions"
            className={
              location.pathname === "/history/transactions" ? "active" : ""
            }
          >
            <span className="left_menu_icon">
              <i className="fas fa-money-bill-wave"></i>
            </span>
            <span className="left_menu_text">{__("Transaction History")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/history/bonus"
            className={location.pathname === "/history/bonus" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-money-bill-wave"></i>
            </span>
            <span className="left_menu_text">{__("Bonus History")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/history/gaming"
            className={location.pathname === "/history/gaming" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-dice"></i>
            </span>
            <span className="left_menu_text">{__("Gaming History")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/balance-transfer"
            className={
              location.pathname === "/balance-transfer" ? "active" : ""
            }
          >
            <span className="left_menu_icon">
              <i className="fas fa-money-bill-wave"></i>
            </span>
            <span className="left_menu_text">{__("Balance Transfer")}</span>
          </Link>
        </li>

        <li>
          <Link
            to="/history/balance-transfer"
            className={
              location.pathname === "/history/balance-transfer" ? "active" : ""
            }
          >
            <span className="left_menu_icon">
              <i className="fas fa-money-bill-wave"></i>
            </span>
            <span className="left_menu_text">
              {__("Balance Transfer History")}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/free-spins"
            className={location.pathname === "/free-spins" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-dice"></i>
            </span>
            <span className="left_menu_text">
              {__("Free Spins")}
              {freeSpinsCount ? <small>({freeSpinsCount})</small> : ""}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/fortunes"
            className={location.pathname === "/fortunes" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-cog"></i>
            </span>
            <span className="left_menu_text">{__("Fortunes")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/notifications"
            className={location.pathname === "/notifications" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-envelope"></i>
            </span>
            <span className="left_menu_text">
              {__("Notifications")}
              {notificationCount ? <small>({notificationCount})</small> : ""}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/messages"
            className={location.pathname === "/messages" ? "active" : ""}
          >
            <span className="left_menu_icon">
              <i className="fas fa-envelope"></i>
            </span>
            <span className="left_menu_text">
              {__("Messages")}
              {messagesCount ? <small>({messagesCount})</small> : ""}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/recommend-us"
            className={
              location.pathname === "/recommend-us" ? "active" : ""
            }
          >
            <span className="left_menu_icon">
              <i className="fas fa-user"></i>
            </span>
            <span className="left_menu_text">{__("Recommend Us")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/access-control"
            className={
              location.pathname === "/access-control" ? "active" : ""
            }
          >
            <span className="left_menu_icon">
              <i className="fas fa-user"></i>
            </span>
            <span className="left_menu_text">{__("Responsible Gaming")}</span>
          </Link>
        </li>
        <li>
          <Link
            to="/account-activity"
            className={
              location.pathname === "/account-activity" ? "active" : ""
            }
          >
            <span className="left_menu_icon">
              <i className="fas fa-user"></i>
            </span>
            <span className="left_menu_text">{__("Account Activity")}</span>
          </Link>
        </li>

        <li>
          <Link to="/">
            <span className="left_menu_icon">
              <i className="fas fa-dice"></i>
            </span>
            <span className="left_menu_text">{__("Game Play")}</span>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default ProfileSideMenu;
