import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "services/ls";
import { __ } from "services/Translator";
import AppContext from "contexts/AppContext";
import api from "services/api";
import GameItem from "elements/GameItem";
import Pagination from "elements/Pagination";
import { Collapse } from "react-bootstrap";

const CategoryGames = (props) => {
  // const menuOpen = props.menuOpen;

  return (
    <>
      <div className="categorized_game_area">
        <section className="single_categorized_game_area">
          <div className="categorized_game_heading_area">
            <div className="categorized_game_heading">{props.categoryName}</div>
            {!props.isSearchResults && props.showMoreIcon && (
              <Link
                to={"/" + props.category}
                className="game_show_more game_categorized_more_games"
              >
                {__("Show More")}
              </Link>
            )}
          </div>

          <div className="game_lists_area popular_category">
            {props.gameList.map((game, index2) => (
              <GameItem key={game.id} game={game} />
            ))}
          </div>
        </section>
      </div>

      {props.showPagination && (
        <>
          <br />
          <br />
          <Pagination
            data={props.pagination}
            onPageChange={props.onPageChange}
          />
        </>
      )}
    </>
  );
};

export default CategoryGames;
