import React from "react";

const Spinner = (props) => {
  return (
    <div className="spinner_overlay">
      <img className="spinner" src="/logo-transparent.png" />
    </div>
  );
};

export default Spinner;
