import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import InputBox from "elements/InputBox";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";

export default function RecommendUs(props) {
  const defaultForm = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const [form, setForm] = useState(defaultForm);

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const doSubmit = async () => {
    props.setSpinner(true);
    var response = await api.post("/recommend-us", form);
    props.setSpinner(false);
    console.log(response.data);
    if (response.status === 200) {
      props.showAlert(null, response.data.message);
      setForm(defaultForm);
    } else {
      if (response.data.validationErrors) {
        props.showAlert(null, response.data.validationErrors, true);
      } else {
        props.showAlert(null, response.data.message, true);
      }
    }
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Recommend Us") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Recommend Us")}</div>

        <div className="my_account_filter_area">
          <div className="filter_form">
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <label>{__("First Name")}</label>
                <InputBox
                  className="form-control"
                  placeholder="First Name"
                  value={form.firstName}
                  onChange={(itemValue) => updateForm(itemValue, "firstName")}
                />
              </div>
              <div className="col-xl-6 col-md-6">
                <label>{__("Last Name")}</label>
                <InputBox
                  className="form-control"
                  placeholder="Last Name"
                  value={form.lastName}
                  onChange={(itemValue) => updateForm(itemValue, "lastName")}
                />
              </div>
              <div className="col-xl-6 col-md-6">
                <label>{__("Email")}</label>
                <InputBox
                  className="form-control"
                  placeholder="Email"
                  value={form.email}
                  onChange={(itemValue) => updateForm(itemValue, "email")}
                />
              </div>
              <div className="col-xl-6 col-md-6" style={{ paddingTop: "30px" }}>
                <button
                  type="button"
                  className="common_brown_button"
                  onClick={doSubmit}
                >
                  {__("Submit")}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </AccountPagesTemplate>
    </>
  );
}
