import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import { __ } from "services/Translator";
import api from "services/api";

const ResendActivationEmail = (props) => {
  const [email, setEmail] = useState(props.email);

  const updateEmail = (ev) => {
    setEmail(ev.target.value);
  };

  useEffect(() => {
    setEmail(props.email);
  }, []);

  const resendEmail = async () => {
    props.setSpinner(true);
    var response = await api.post("/resend-activation-mail", { email });
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setEmail("");
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder={__("Email")}
        className="form-control"
        name="email"
        value={email}
        onChange={updateEmail}
      />
      <br />
      <button
        onClick={resendEmail}
        type="submit"
        className="common_brown_button"
      >
        {__("Submit")}
      </button>
    </div>
  );
};

export default ResendActivationEmail;
