import React, { useState, useEffect, useRef } from "react";
import {
  Redirect,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import config from "configs/config";
import api from "services/api";
import moneyFormatter from "services/moneyFormatter";
import MainMenu from "elements/MainMenu";
import RightSidebar from "elements/RightSidebar";
import SupportSidebar from "elements/SupportSidebar";
import Translator, { __ } from "services/Translator";

// import passwordValidate from "services/passwordValidate";
import USD from "assets/usd.svg";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";
import Login, { loginData } from "elements/Login";
import Signup from "elements/Signup";
const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const accountProgress = props.accountProgress;
  const setSpinner = props.setSpinner;

  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(response.data);
    }
  };

  const switchLanguage = (lang) => {
    var homeUrl = history.createHref({ pathname: "/" });
    var currentUrl = window.location.href;
    var newUrl = currentUrl.replace(homeUrl, "/" + lang.code + "/");
    Translator.setSelectedLanguage(lang);
    window.location.href = newUrl;
  };

  const openLoginModal = () => {
    props.setDisplayedModal("login");
  };
  const openSignupModal = () => {
    props.setDisplayedModal("signup");
  };

  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  useEffect(() => {
    if (mobileNavOpen) {
      setMobileNavOpen();
    }
  }, [location.pathname]);

  //Security Pop-up functionality START'S here
  const ping = async () => {
    var response = await api.get("/ping");
    if (response.status === 200) {
      setSecurityPopupModal(false);
    } else if (response.status === 403) {
      if (response.data && response.data.showSecurityPopup) {
        if (!isAuthenticated) {
          setSecurityPopupModal(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      ping();
    }
    const interval = setInterval(() => {
      if (!isAuthenticated) {
        ping();
      }
    }, 15000);
    getLanguages();
    return () => clearInterval(interval);
  }, []);

  const [securityPopupModal, setSecurityPopupModal] = useState(false);
  const [securityPopupData, setSecurityPopupData] = useState({
    login: "",
    password: "",
  });

  const [securityPopupDataErrors, setSecurityPopupDataErrors] = useState({
    login: "",
    password: "",
  });

  const updateSecurityPopupInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    setSecurityPopupData({ ...securityPopupData, [field]: value });
  };

  const securityPopupLogin = async (ev) => {
    ev.preventDefault();
    var valid = validateSecurityPopupForm();
    if (valid) {
      props.setSpinner(true);
      var response = await api.post("/pre-login", securityPopupData);
      props.setSpinner(false);
      if (response.status == 200) {
        //Here, no actual user token is provided. So, user is still not logged in
        //However, this temporary token will get past the security popup
        await auth.login(response.data.user);
        setSecurityPopupModal(false);
      }
    }
  };

  const validateSecurityPopupForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    if (
      securityPopupData.login.length < 2 ||
      securityPopupData.login.length > 250
    ) {
      errorsTmp = { ...errorsTmp, login: "Please enter the user name" };
      valid = false;
    }

    if (
      securityPopupData.password.length < 2 ||
      securityPopupData.password.length > 250
    ) {
      errorsTmp = { ...errorsTmp, password: "Please enter the password" };
      valid = false;
    }

    setSecurityPopupDataErrors(errorsTmp);

    return valid;
  };

  //Security Pop-up functionality END'S here

  /////////NEw ones
  const [siteVariables, setSiteVariables] = useState({});
  const [gameMode, setGameMode] = useState("casino");
  const selectedLanguage = Translator.getSelectedLanguage();

  return (
    <>
      <header>
        <div className="page__header">
          <div className="container">
            <Link to="/" className="logo">
              <img
                src={require("assets/images/common/logo-xbit.png").default}
                alt={siteVariables.brand_name}
              />
            </Link>
            <div className="row">
              <div className="d-md-none col-6">
                <div className="user-nav">
                  <button
                    type="button"
                    onClick={toggleMobileNav}
                    className="user-nav__button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="user-nav__icon"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="col-6 d-none d-md-block text-lg-left main_menu_item">
                <div
                  className={
                    "user-nav no_outline " +
                    (gameMode === "casino" ? "selected" : "")
                  }
                >
                  <Link to="/" className="user-nav__button ">
                    <span className="user-nav__label">
                      {__("Gaming Platform")}
                    </span>
                  </Link>
                </div>
                <div className="user-nav deluxe_btn">
                  <Link to="/" className="user-nav__button">
                    <span className="user-nav__label">XBIT</span>
                  </Link>
                </div>
              </div>
              <div className=" col-6 text-right language-dropdown">
                {isAuthenticated ? (
                  <div className="header_player_logged_in_area">
                    <ul className="player_info_menu">
                      <li className="player_balance_info d-none d-lg-inline-block">
                        <a
                          className="player_balance_info_dropdown_area"
                          data-toggle="dropdown"
                          href="#"
                        >
                          <span className="total_balance">
                            XBU{" "}
                            {moneyFormatter.balanceFormat(
                              userBalance.total_balance
                            )}
                          </span>
                          <span className="caret"></span>
                        </a>
                        <div className="dropdown-menu dropdown_menu_extend">
                          <ul className="">
                            <li>
                              <span>{__("Cash")}</span>
                              <span>
                                XBU{" "}
                                <span className="cash_balance">
                                  {moneyFormatter.balanceFormat(
                                    userBalance.cash_balance
                                  )}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span>{__("Bonuses")}</span>
                              <span>
                                XBB{" "}
                                <span className="bonus_balance">
                                  {moneyFormatter.balanceFormat(
                                    userBalance.bonus_balance
                                  )}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li style={{ verticalAlign: "sub" }}>
                        <a
                          href="#"
                          className="open_sidebar_bar fdfdfd"
                          onClick={toggleRightSidebar}
                        >
                          <i className="fas fa-user"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <>
                    <div className="user-nav">
                      <button
                        type="button"
                        className="user-nav__button"
                        onClick={openLoginModal}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="user-nav__icon"
                        >
                          <path
                            fill="currentColor"
                            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                          />
                          <path fill="none" d="M0 0h24v24H0z" />
                        </svg>
                      </button>
                    </div>

                    <div className="user-nav signup_menu">
                      <a
                        href="#"
                        className="user-nav__button"
                        onClick={openSignupModal}
                      >
                        <span className="user-nav__label">
                          {__("Sign Up!")}
                        </span>
                      </a>
                    </div>
                  </>
                )}
                {false && (
                  <div className="user-nav">
                    <Link
                      to="#"
                      style={{
                        paddingTop: "7px",
                        paddingBottom: "6px",
                        fontSize: "20px",
                      }}
                      className="user-nav__button chat_button"
                      onClick={() =>
                        props.setIsChatVisible(!props.isChatVisible)
                      }
                    >
                      <i className="fas fa-comments"></i>
                    </Link>
                  </div>
                )}

                <div className="user-nav support_menu">
                  <Link
                    to="/support/faq"
                    style={{ paddingTop: "12px" }}
                    className="user-nav__button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="user-nav__icon"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
                      />
                    </svg>
                  </Link>
                </div>

                <div className="user-nav lang_dropdown_area ">
                  <a
                    href="#"
                    type="button"
                    data-toggle="dropdown"
                    style={{ padding: "11px" }}
                    className="user-nav__button"
                  >
                    <div className="language_code_text user-nav__label">
                      {selectedLanguage.alias
                        ? selectedLanguage.alias
                        : selectedLanguage.language}
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="user-nav__arrow"
                    >
                      <path
                        fill="currentColor"
                        d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
                      />
                      <path fill="none" d="M0 0h24v24H0V0z" />
                    </svg>
                  </a>

                  <ul className="dropdown-menu">
                    {languages.map((item, index) => (
                      <li key={index}>
                        <a
                          href="#"
                          className="selectedLang"
                          onClick={() => switchLanguage(item)}
                        >
                          <span>{item.alias ? item.alias : item.language}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <MainMenu />

        {!!props.breadcrumb.length && (
          <div className="container">
            <nav className="breadcrumb_area" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link title={__("Home")} to="/">
                    {__("Home")}
                  </Link>
                </li>
                {props.breadcrumb.map((item, index) => (
                  <li className="breadcrumb-item" key={index}>
                    <Link title={item.name} to={item.link}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        )}

        <div className="clearfix"></div>
      </header>

      <div
        className={
          "h5 mobile-nav " + (mobileNavOpen ? "mobile-nav_visible" : "")
        }
      >
        <Link to="/" className="mobile-nav__link">
          {__("Play Now")}
        </Link>

        <Link to="/promotions" className="mobile-nav__link">
          {__("Promotions")}
        </Link>
        <Link to="/cashier" className="mobile-nav__link">
          {__("Cashier")}
        </Link>
        <Link to="/vip-lounge" className="mobile-nav__link">
          {__("VIP Lounge")}
        </Link>
        {!isAuthenticated && (
          <a href="#" className="mobile-nav__link" onClick={openSignupModal}>
            {__("Register")}
          </a>
        )}

        <div className="mobile_language_switcher lang_dropdown_area">
          <a href="#" data-toggle="dropdown" className="user-nav__button">
            <div className="language_code_text">
              {selectedLanguage.alias
                ? selectedLanguage.alias
                : selectedLanguage.language}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="user-nav__arrow"
            >
              <path
                fill="currentColor"
                d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
              />
              <path fill="none" d="M0 0h24v24H0V0z" />
            </svg>
          </a>

          <ul className="dropdown-menu">
            {languages.map((item, index) => (
              <li key={index}>
                <a
                  href="#"
                  className="selectedLang"
                  onClick={() => switchLanguage(item)}
                >
                  <span>{item.alias ? item.alias : item.language}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <button
          type="button"
          className="mobile-nav__close"
          onClick={toggleMobileNav}
        ></button>
      </div>

      <RightSidebar
        userBalance={userBalance}
        userDetails={userDetails}
        accountProgress={accountProgress}
        rightSidebarOpen={rightSidebarOpen}
        toggleRightSidebar={toggleRightSidebar}
      />

      <Modal show={securityPopupModal}>
        <Modal.Body className="login_modal forgot_pass">
          <div className="modal_headline">
            Maintenance. Back online in 30 minutes…
          </div>
          <form onSubmit={securityPopupLogin}>
            <div className="form_area">
              <div className="input_item">
                <div className="name">
                  User Name<span className="requried_icon">*</span>
                </div>
                <input
                  type="text"
                  name="login"
                  onChange={updateSecurityPopupInput}
                  value={securityPopupData.login}
                  className={
                    "form-control name_value " +
                    (securityPopupDataErrors.login ? "error" : "")
                  }
                />
                <div className="errorMsg">{securityPopupDataErrors.login}</div>
              </div>

              <div className="input_item">
                <div className="name">
                  Password<span className="requried_icon">*</span>
                </div>
                <input
                  type="password"
                  name="password"
                  onChange={updateSecurityPopupInput}
                  value={securityPopupData.password}
                  className={
                    "form-control name_value " +
                    (securityPopupDataErrors.password ? "error" : "")
                  }
                />
                <div className="errorMsg">
                  {securityPopupDataErrors.password}
                </div>
              </div>
              <div className="login_button">
                <Button type="submit" className="login_btn">
                  Login
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Login {...props} />
      <Signup {...props} />
      <SupportSidebar {...props} />
    </>
  );
};

export default Header;
