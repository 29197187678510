import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import config from "configs/config";
import api from "services/api";
import { __ } from "services/Translator";
import { isMobile, cauculateLandScape } from "services/isMobile";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = (props) => {
  // const menuOpen = props.menuOpen;
  const openSignupModal = () => {
    props.setDisplayedModal("signup");
  };

  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    var response = await api.get("/banners");
    if (response.status === 200) {
      setBanners(response.data);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  const sliderSetting = {
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 2000,
    infinite: true,
    dots: false,
    fade: true,
  };

  return (
    <>
      <section className="banner_section">
        <div className="banner_area">
          <Slider {...sliderSetting}>
            {banners.map((item, index) => (
              <div className="single_banner" key={index}>
                <img
                  className="big_banner"
                  src={item.banner}
                  alt={item.banner}
                />
                <img
                  className="medium_banner"
                  src={item.medium}
                  alt={item.medium}
                />
                <img
                  className="small_banner"
                  src={item.small}
                  alt={item.small}
                />
                <div className="banner_content">
                  <h1 className="main_title">{item.title}</h1>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <a
          className="btn btn-success registration_btn"
          onClick={openSignupModal}
          href="#"
        >
          {__("Sign Up")}
        </a>
      </section>
    </>
  );
};

export default Banner;
