import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import InputBox from "elements/InputBox";
import { __ } from "services/Translator";

import AccountPagesTemplate from "elements/AccountPagesTemplate";

export default function BalanceTransfer(props) {
  const defaultForm = {
    receiverEmail: "",
    amount: 0,
  };

  const [form, setForm] = useState(defaultForm);

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const transferBalance = async () => {
    props.setSpinner(true);
    var response = await api.post("/transfer-balance", form);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert(null, response.data.message);
      setForm(defaultForm);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    props.setBreadcrumb([{ name: __("Balance Transfer") }]);
  }, []);

  return (
    <>
      <AccountPagesTemplate>
        <div className="my_account_heading">{__("Balance Transfer")}</div>

        <div className="my_account_filter_area">
          <div className="filter_form">
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <label>{__("Receiver Email")}</label>
                <InputBox
                  className="form-control"
                  placeholder="Receiver Email"
                  value={form.receiverEmail}
                  onChange={(itemValue) =>
                    updateForm(itemValue, "receiverEmail")
                  }
                />
              </div>
              <div className="col-xl-6 col-md-6">
                <label>{__("Amount")}</label>

                <InputBox
                  type="number"
                  className="form-control"
                  placeholder="Amount"
                  value={form.amount}
                  onChange={(itemValue) => updateForm(itemValue, "amount")}
                />
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="my_account_filter_action_area">
              <button
                type="button"
                className="common_brown_button"
                onClick={transferBalance}
              >
                {__("Transfer")}
              </button>{" "}
            </div>
          </div>
        </div>
      </AccountPagesTemplate>
    </>
  );
}
